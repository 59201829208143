.digital-scorecard {
    @include box-shadow-style;
    border-radius: 10px;
    padding: 27px 10px 27px 10px;
    @include breakpoints(small){
        padding: 15px 15px 0;;
    }
    .heading-p {
        font-size: 24px;
        font-weight: 500;
        letter-spacing: 0.57px;
        @include breakpoints('small') {
            padding: 0;
        }
    }
    .heading-s {
        text-align: right;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0.75px;
        @include breakpoints('small') {
            text-align: left;
            margin: 10px 0;
            padding: 0;
        }
    }
    
    .box {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        margin-top: 60px;
        @include breakpoints(small){
            margin-top: 20px;
        }
        .rect {
            width: 40%;
            max-width: 425px;
            margin: 0 20px 30px;
            padding: 22px 9px 14px 9px;
            border-radius: 3px;
            border: solid 1px map-get($colors, black1);
            background-color: map-get($colors, write-review); 
            text-align:center;
            @include breakpoints('medium') {
                width:315px;
            }
            @include breakpoints('small') {
                width: 100%;
                margin-left: 0;
                margin-right: 0;
            }
            .title {
                font-size: 22px;
                font-weight: 500;
                letter-spacing: 0.52px;
                margin: 0 0 5px;
                line-height: normal;
                @include breakpoints(medium){
                    font-size: 18px;
                }
            }
            .digit {
                font-size: 70px;
                font-weight: bold;
                letter-spacing: 1.67px;
                line-height: normal;
                @include breakpoints(medium){
                    font-size: 60px;
                }
            }
            &:hover {
                border: solid 1px map-get($colors, primary-main);;
                background-color: map-get($colors, primary-main);;
                color: map-get($colors, white1);
            }
            &.active {
                border: solid 1px map-get($colors, primary-main);;
                background-color: map-get($colors, primary-main);;
                color: map-get($colors, white1);
            }            
        }
    }
    .share-score{
        @include wrap-flx;
        justify-content: flex-end;
        align-items: center;
        @include breakpoints(medium){
            justify-content: flex-start;
        }
        span{
            margin-right: 10px;
            @include breakpoints(small){
                &:first-child{
                    margin-bottom: 5px;
                }
            }
            &:last-child{
                margin: 0;
            }
            a{
                width: 130px;
                height: 45px;
                display: block;                
                &.fbtxt, &.twitxt{
                    text-align: center;
                    padding: 11px 0 0 0;
                    font-size: 15px;
                    letter-spacing: 1px;
                    @include border-radius(4px);
                    strong{
                        font-weight: 500;
                        position: relative;
                        &:before{
                            @include sprite;
                        }
                    }
                }
                &.fbtxt{
                    color: map-get($colors, fbcolor);
                    border: 1px solid map-get($colors, fbcolor);
                    strong{
                        &:before{
                            background-position: -375px -309px;
                            width: 11px;
                            height: 19px;
                            left: -22px;
                            top: -2px;
                        }
                    }
                }
                &.twitxt{
                    color: map-get($colors, twitter-color);
                    border: 1px solid map-get($colors, twitter-color);
                    strong{
                        padding: 0 0 0 15px;
                        &:before{
                            background-position: -371px -252px;
                            width: 20px;
                            height: 17px;
                            left: -14px;
                            top: -2px;
                        }
                    }
                }
            }
        }
    }
}