.payment-receipt-wrap {
  margin: 30px 0;
  @include breakpoints(small) {
    margin: 20px 0;
  }
  .receipt-subtext {
    font-size: 24px;
    letter-spacing: 1px;
    margin: 20px 0 35px;
    @include breakpoints(small) {
      font-size: 16px;
      margin: 20px 0 20px;
    }
  }
  .receipt-box {
    @include box-shadow-style;
    @include border-radius(10px);
    padding: 45px 45px 30px;
    @include breakpoints(medium) {
      padding: 20px;
    }
    .receipt-box-outer {
      max-width: 100%;
      @include breakpoints(medium) {
        overflow-x: scroll;
      }
      .receipt-inner {
        @include breakpoints(medium) {
          width: 1000px;
        }
        @include breakpoints(small) {
          width: 750px;
        }
        .receipt-top-head {
          @include wrap-flx;
          justify-content: space-between;
          font-size: 20px;
          letter-spacing: 0.8px;
          line-height: 1.5;
          color: map-get($colors, black1);
          font-weight: 500;
          @include breakpoints(small) {
            font-size: 16px;
          }
          .receipt-top-left {
            width: 265px;
            @include breakpoints(small) {
              width: 220px;
            }
            .logo-receipt {
              margin-bottom: 15px;
            }
            .receipt-head-address {
              padding: 0;
            }
          }
          .receipt-top-right {
            text-align: right;
            .next-btn-pro {
              font-size: 16px;
              width: 140px;
              padding: 12px 0;
              @include border-radius(0);
              margin-top: 5px;
            }
            .right-text {
              margin-top: 52px;
            }
          }
        }
        .receipt-info-table {
          border-top: 1px solid map-get($colors, modal-border);
          border-bottom: 1px solid map-get($colors, modal-border);
          margin: 30px 0 15px;
          padding: 30px 0;
          @include breakpoints(small) {
            margin: 0px 0 15px;
            padding: 15px 0;
          }
          .info-table-inner {
            @include wrap-flx;
            @include reg-font;
            justify-content: space-between;
            line-height: 1.7;
            span {
              width: 14%;
              @include breakpoints(large) {
                width: 17%;
              }
              text-align: left;
              &:first-child {
                width: 44%;
                text-align: left;
                @include breakpoints(large) {
                  width: 32%;
                }
              }
            }
            &:first-child {
              margin-bottom: 10px;
              span {
                font-size: 20px;
                font-weight: 500;
                @include breakpoints(small) {
                  font-size: 17px;
                }
              }
              @include breakpoints(small) {
                margin-bottom: 5px;
              }
            }
          }
        }
        .receipt-description-table {
          .recept-des-inner {
            @include wrap-flx;
            @include reg-font;
            justify-content: space-between;
            line-height: 1.7;
            margin-bottom: 20px;
            @include breakpoints(small) {
              margin-bottom: 10px;
            }
            span {
              width: 20%;
              text-align: right;
              @include breakpoints(small) {
                width: 16%;
              }
              &:nth-child(1) {
                width: 15%;
                text-align: left;
                @include breakpoints(small) {
                  width: 8%;
                }
              }
              &:nth-child(2) {
                width: 25%;
                text-align: left;
                @include breakpoints(small) {
                  width: 33%;
                }
              }
            }
            &:first-child {
              border-bottom: 1px solid map-get($colors, modal-border);
              padding-bottom: 15px;
              margin-bottom: 30px;
              @include breakpoints(small) {
                margin-bottom: 15px;
              }
              span {
                font-size: 20px;
                font-weight: 500;
                @include breakpoints(small) {
                  font-size: 17px;
                }
              }
            }
            &:last-child {
              border-bottom: 1px solid map-get($colors, modal-border);
              padding-bottom: 30px;
              margin-bottom: 30px;
              @include breakpoints(small) {
                padding-bottom: 15px;
                margin-bottom: 15px;
              }
            }
          }
        }
        .receipt-total-wrap {
          .receipt-total-inner {
            @include wrap-flx;
            justify-content: flex-end;
            font-size: 20px;
            margin-bottom: 10px;
            @include breakpoints(small) {
              font-size: 18px;
            }
            span {
              width: 20%;
              text-align: right;
              &:first-child {
                font-weight: 500;
              }
            }
            &:last-child {
              margin-top: 25px;
              span {
                &:last-child {
                  font-weight: 700;
                  color: map-get($colors, primary-main);
                }
              }
            }
          }
        }
      }
    }
    .receipt-greybox {
      background-color: map-get($colors, grey-plan);
      color: map-get($colors, grey-dark);
      padding: 25px;
      @include reg-font;
      margin-top: 30px;
      @include breakpoints(small) {
        padding: 10px;
      }
    }
  }
  .receipt-bott-button {
    text-align: center;
    .next-btn-pro {
      font-size: 16px;
      @include border-radius(0);
      width: 500px;
      margin: 55px 0 15px;
      @include breakpoints(small) {
        width: 100%;
        margin: 30px 0 10px;
      }
    }
  }
}
