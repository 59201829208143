.my-account-wrap {
  .ipad-pro {
    min-height: 50vh;
  }
  h1 {
    margin: 30px 0 42px;
    letter-spacing: 1.17px;
    font-weight: 700;
    @include breakpoints(small) {
      margin: 20px 0;
    }
  }
  &.my-acc-inner {
    h1 {
      margin-bottom: 34px;
      font-weight: 700;
      letter-spacing: 1.17px;
      @include breakpoints(medium) {
        margin-bottom: 15px;
      }
    }
    .back-btn {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 43px;
      @include breakpoints(medium) {
        margin-bottom: 15px;
      }
      @include breakpoints(small) {
        font-size: 16px;
      }
      a {
        color: map-get($colors, grey-dark);
      }
      .bk-arrow {
        position: relative;
        margin-right: 5px;
        width: 24px;
        top: 3px;
        @include breakpoints(medium) {
          width: 18px;
          top: 7px;
        }
        @include breakpoints(small) {
          top: 2px;
        }
      }
    }
    .edit-profile-btn {
      position: absolute;
      right: 0;
      top: 10px;
      z-index: 1;
      cursor: pointer;
      @include breakpoints(small) {
        top: 0;
      }
    }
    .my-acc-info-box {
      @include breakpoints(small) {
        .col-md-7 {
          width: 95% !important;
        }
      }
      h3 {
        margin-bottom: 25px;
        &.delete-prof-head {
          margin-bottom: 36px;
        }
      }
    }
  }
  .edit-btn-profile {
    @include breakpoints(small) {
      @include wrap-flx;
      justify-content: space-between;
      margin-top: 15px;
    }
    .next-btn-pro {
      width: 143px;
      margin: 0 0 0 20px;
      font-size: 16px;
      height: 56px;
      @include breakpoints(large) {
        width: 130px;
      }
      @include breakpoints(medium) {
        width: 100%;
        margin: 10px 0 0 0px;
      }
      @include breakpoints(small) {
        width: 44%;
        margin-bottom: 0;
        padding: 0;
      }
    }
    .prev-btn {
      @include prev-btn;
      margin: 0;
      font-size: 16px;
      padding: 0;
      justify-content: center;
      width: 143px;
      height: 56px;
      display: inline-flex;
      align-items: center;
      @include breakpoints(large) {
        width: 130px;
      }
      @include breakpoints(medium) {
        text-align: center;
        width: 100%;
        margin-top: 0;
      }
      @include breakpoints(small) {
        width: 44%;
        padding: 10px 0;
        margin-top: 10px;
      }
    }
  }

  .account-edit-box {
    @include wrap-flx;
    align-items: center;
    margin-bottom: 10px;
    // height: 50px;
    .edit-caption {
      @include reg-font;
      width: 35%;
      line-height: 1.4;
      padding: 12px 0;
      @include breakpoints(medium) {
        width: 100%;
      }
    }
    .edit-field {
      @include bold-font;
      width: 40%;
      &.account-birth-fields {
        width: 65%;
        @include breakpoints(medium) {
          width: 90%;
        }
      }
      @include breakpoints(medium) {
        width: 90%;
      }
      &.switch-btn {
        .switch input:checked + .slider:after {
          content: "On";
        }
        .switch {
          left: 35px;
          .slider {
            &:after {
              font-weight: 900;
              content: "Off";
            }
          }
        }
      }
      &.edit-field-email {
        width: 61%;
        @include breakpoints(medium) {
          width: 90%;
        }
      }
    }
    .input-pro {
      @include bold-font;
      padding: 10px 20px;
      font-weight: 900;
    }
  }
  .my-acc-top-head {
    font-size: 20px;
    color: map-get($colors, grey-dark);
    @include wrap-flx;
    font-weight: 700;
    margin-bottom: 33px;
    @include breakpoints(small) {
      font-size: 16px;
      margin-bottom: 20px;
    }
    div {
      span {
        cursor: pointer;
      }
      &:first-child {
        margin: 0 80px 0 0;
        @include breakpoints(small) {
          margin-right: 30px;
        }
      }
      .acc-info-icon {
        margin: 0 18px 0 3px;
        filter: grayscale(100%);
        @include breakpoints(small) {
          margin-right: 5px;
          width: 20px;
        }
      }
      .under-line {
        &:after {
          height: 3px;
          background-color: map-get($colors, primary-main);
          bottom: -18px;
          @include breakpoints(small) {
            height: 2px;
          }
        }
      }
      &:hover {
        .acc-info-icon {
          filter: grayscale(0);
          @include transition(all 0.2s ease-in-out);
        }
        span {
          color: map-get($colors, primary-main);
          @include transition(all 0.2s ease-in-out);
        }
      }
      &.active {
        .acc-info-icon {
          filter: grayscale(0);
        }
        span {
          color: map-get($colors, primary-main);
        }
        .under-line {
          &:after {
            bottom: -18px;
            width: 100%;
          }
        }
      }
    }
  }
  .my-acc-info-box {
    @include box-shadow-style;
    @include border-radius(10px);
    padding: 30px 50px 17px;
    margin-bottom: 30px;
    @include breakpoints(small) {
      padding: 15px 15px 0;
    }
    h3 {
      letter-spacing: 1px;
      margin-bottom: 20px;
      font-weight: 500;
      font-size: 24px;
      line-height: 1.33;
      @include breakpoints(small) {
        margin-bottom: 10px;
        font-size: 18px;
      }
    }
    .account-info-list {
      li {
        padding: 0;
        a {
          display: block;
          position: relative;
          color: map-get($colors, black1);
          @include medium-font;
          border-bottom: 1px solid map-get($colors, modal-border);
          // margin-bottom: 15px;
          padding-bottom: 19px;
          padding-top: 16px;
          padding-left: 10px;
          line-height: 1.69;
          @include breakpoints(small) {
            margin-bottom: 10px;
            // padding-bottom: 10px;
            padding-top: 10px;
          }
          &:hover {
            border-left: 0;
            border-right: 0;
            border-top: 0;
            // padding-left:10px;
            color: map-get($colors, primary-main);
            background-color: #f6fafc;
            &:after {
              border-color: map-get($colors, primary-main);
            }
          }
          &:after {
            content: "";
            position: absolute;
            right: 25px;
            top: 20px;
            border: solid map-get($colors, ford-gray);
            border-width: 0 4px 4px 0;
            padding: 4px;
            transform: rotate(-45deg);
            @include breakpoints(small) {
              right: 5px;
              top: 15px;
            }
          }
        }
        &:last-child {
          a {
            border: 0;
            &:hover {
              @include box-shadow(none);
            }
          }
        }
      }
    }
    &.two-step-innerbox,
    &.cellphone-innerbox,
    &.recovery-mail-innerbox {
      height: 185px;
      @include breakpoints(medium) {
        height: auto;
      }
    }
    &.profile-name-innerbox {
      height: 291px;
      @include breakpoints(medium) {
        height: auto;
      }
    }
    &.dental-ins-innerbox,
    &.vision-ins-innerbox,
    &.primary-ins-innerbox {
      height: 355px;
      @include breakpoints(medium) {
        height: auto;
      }
      .account-edit-box {
        .edit-field {
          width: 33%;
          @include breakpoints(large) {
            width: 60%;
          }
          @include breakpoints(medium) {
            width: 90%;
          }
        }
      }
    }
    &.dental-ins-innerbox {
      select {
        font-weight: 700;
        font-size: 16px;
        font-family: $roboto;
        background-image: url("#{$imagePath}drop-arrow-search.png");
        background-size: 17px;
        letter-spacing: 0.7px;
      }
    }
    &.phoneno-innerbox {
      height: 298px;
      @include breakpoints(medium) {
        height: auto;
      }
      .account-edit-box {
        .edit-field {
          width: 33%;
          @include breakpoints(large) {
            width: 60%;
          }
          @include breakpoints(medium) {
            width: 90%;
          }
        }
      }
    }
    &.childs-ins-innerbox {
      height: 604px;
      @include breakpoints(medium) {
        height: auto;
      }
      .account-edit-box {
        margin-bottom: 15px;
        @include breakpoints(medium) {
          margin-bottom: 10px;
        }
      }
    }
    &.sex-pronoun-innerbox {
      height: 232px;
      @include breakpoints(medium) {
        height: auto;
      }
      .myform {
        select {
          font-weight: 700;
          font-size: 16px;
          font-family: $roboto;
          background-image: url("#{$imagePath}drop-arrow-search.png");
          background-size: 17px;
          width: 173px;
          letter-spacing: 0.7px;
          @include breakpoints(small) {
            width: 100%;
          }
        }
      }
    }
    &.birthday-pro-innerbox {
      height: 171px;
      @include breakpoints(medium) {
        height: auto;
      }
      .account-birth-fields {
        div {
          width: 155px;
          @include breakpoints(medium) {
            width: 125px;
          }
          @include breakpoints(small) {
            width: 100%;
          }
        }
      }
      .myform {
        select {
          font-weight: 700;
          font-size: 16px;
          font-family: $roboto;
          background-image: url("#{$imagePath}drop-arrow-search.png");
          background-size: 17px;
          letter-spacing: 0.67px;
          line-height: 27px;
          padding: 10px 20px;
          @include breakpoints(small) {
            width: 100%;
          }
        }
      }
    }
  }
  .account-birth-fields {
    @include wrap-flx;
    flex-wrap: nowrap;
    div {
      width: 130px;
      margin-right: 15px;
      @include bold-font;
      @include breakpoints(large) {
        width: 123px;
      }
      @include breakpoints(medium) {
        width: 100%;
      }
      @include breakpoints(small) {
        width: 100%;
        margin-right: 10px;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .gender-edit-box {
    @include wrap-flx;
    .cus-radio {
      label {
        @include bold-font;
        &:before {
          height: 24px;
          width: 24px;
        }
      }
      > input {
        &:checked {
          + label {
            &:before {
              border-color: map-get($colors, primary-main);
            }
            &:after {
              top: 10px;
              left: 4px;
              width: 16px;
              height: 16px;
            }
          }
        }
      }
      &:first-child {
        margin-right: 50px;
      }
    }
  }
  .myform {
    select {
      font-weight: 900;
      color: map-get($colors, black1);
      padding: 10px 20px;
      @include breakpoints(small) {
        padding: 10px;
      }
    }
  }
  .delete-profile-txt {
    @include reg-font;
    font-size: 24px;
    @include breakpoints(small) {
      font-size: 16px;
    }
  }
  .reactive-acc-txt {
    text-align: center;
    p {
      @include reg-font;
      font-size: 24px;
      padding: 0;
      margin: 10px 0 30px 0;
      @include breakpoints(small) {
        font-size: 16px;
        margin: 10px 0 15px 0;
      }
    }
    &.subs-canceled {
      padding: 10% 0;
      @include breakpoints(small) {
        padding: 20% 0;
      }
    }
  }
  .delete-profile-btn {
    margin: 29px 0;
    text-align: center;
    @include breakpoints(small) {
      margin: 10px 0 10px;
    }
    input {
      width: 250px;
      font-weight: 700;
      border-color: map-get($colors, delete-color);
      color: map-get($colors, delete-color);
    }
  }
  .info-icon-step {
    position: relative;
    top: 6px;
    margin-left: 10px;
    img {
      width: 24px;
      cursor: pointer;
    }
    @include breakpoints(medium) {
      top: 2px;
    }
    .info-text {
      @include callout;
      @include breakpoints(small) {
        width: 275px;
        right: -105px;
        font-size: 11px;
      }
      &:after {
        @include breakpoints(small) {
          left: 150px;
        }
      }
    }
    &:hover {
      .info-text {
        display: block;
        @include breakpoints(small) {
          top: -117px;
        }
      }
    }
  }
  .pass-rules {
    margin: 40px 0 7px;
    line-height: 26px;
    @include breakpoints(small) {
      margin: 20px 0 10px;
    }
  }
}
.reactive-wrap {
  h1 {
    margin-bottom: 30px;
  }
  .reactive-btn {
    margin: 60px 0 50px;
    text-align: center;
    @include breakpoints(small) {
      margin: 0px 0 30px;
    }
    input {
      width: 250px;
      font-size: 16px;
    }
  }
}
