.dashboard-heading{
    @include wrap-flx;
    margin: 25px 0 20px;
    align-items: center;
    @include breakpoints(small){
        margin: 20px 0 30px;
    }
    @include breakpoints(small){
        margin: 15px 0 20px;
    }
    h1{
        font-size: 26px;
        font-weight: 700;
        letter-spacing: 1.08px;
    }
}
.dashboard-top-tabs-outer{
    padding: 0;
    @include breakpoints(medium){
        max-width: 100%;
        overflow-x: scroll;
        margin-bottom: 20px;
    }
    @include breakpoints(small){
        margin-bottom: 10px;
    }
    .dashboard-top-tabs{
        @include wrap-flx;
        justify-content: space-between;
        margin-bottom: 35px;
        @include breakpoints(medium){
            width: 850px;
            margin-bottom: 20px;
        }
        div{
            width: 23.5%;
            @include bold-font;
            a{
                color: map-get($colors, grey-dark);           
                letter-spacing: 1.1px;
                text-align: center;
                display: block;
                border: 1px solid map-get($colors, grey-dark);
                padding: 13px 0;
                @include border-radius(4px);
                @include breakpoints(small){
                    padding: 10px 0;
                }
                &:hover{
                    // color: map-get($colors, primary-main);
                    border-color: map-get($colors, primary-main);
                    &:after{
                        display: none;
                    }
                }
                &:after{
                    background-color: map-get($colors, primary-main);
                    height: 4px;
                    bottom: -12px;
                }
            }
            &.active{
                a{
                    color: map-get($colors, primary-main);
                    border-color: map-get($colors, primary-main);
                    &:after{
                        background-color: map-get($colors, primary-main);
                        height: 4px;
                        bottom: -12px;
                        width: 100%;
                        display: block;
                    }
                }
            }
        }
    }
}