@include breakpoints(medium) {
  html {
    overflow-x: hidden;
  }
}
.logo-main {
  @include wrap-flx;
  align-items: center;
  .logo-icon {
    width: 32px;
    @include breakpoints(medium) {
      width: 24px;
    }
    @include breakpoints(small) {
      width: 22px;
    }
  }
  span {
    @extend %logo-text-font;
    position: relative;
    margin-left: 4px;
    @include breakpoints(small) {
      margin-left: 2px;
      font-size: 22px;
    }
  }
  @include breakpoints(small) {
    flex-wrap: nowrap;
  }
}
.header {
  position: relative;
  z-index: 50;
  .covid-strip {
    @include covid19-block;
    &.error {
      background-color: #d93025;
    }
    @include breakpoints(medium) {
      font-size: 12px;
      .info-covid-icon {
        width: 17px;
      }
      .covid-close-icon {
        top: 14px;
      }
    }
    @include breakpoints(small) {
      font-size: 12px;
      padding: 5px 10px;
      span {
        display: block;
      }
      .info-covid-icon {
        width: 14px;
      }
      .covid-close-icon {
        top: 5px;
        right: 5px;
      }
    }
  }
  background-color: map-get($colors, white1);
  border-bottom: 1px solid map-get($colors, border-grey);
  .header-inner-before-login {
    @include wrap-flx;
    justify-content: flex-end;
    align-items: center;
    padding: 30px 0 26px;
    @include breakpoints(medium) {
      padding: 15px 0;
    }
    @include breakpoints(small) {
      justify-content: space-between;
      padding: 15px 0 10px;
      align-items: normal;
    }
    .header-logo {
      position: absolute;
      left: 0%;
      @include breakpoints(medium) {
        display: none;
      }
      @include breakpoints(small) {
        display: none;
      }
      width: 30%;
      img {
        @include breakpoints(medium) {
          display: none;
        }
      }
    }
  }
  .header-inner {
    padding: 30px 0;
    position: relative;
    .header-left {
      padding: 0;
      width: 20%;
      @include breakpoints(medium) {
        width: 70%;
      }
      img {
        width: 246px;
        @include breakpoints(medium) {
          width: 180px;
        }
      }
    }
  }
  .sign-in-block {
    @include create-sign;
    position: relative;
    @include breakpoints(small) {
      font-size: 12px;
    }
    .home-menu {
      @include home-menu;
      .home-menu-inner {
        @include home-menu-inner;
        a {
          .home-menu-links {
            color: #000000;
          }
          &:first-child {
            .home-menu-links {
              border-bottom: solid 1px #b9b9b9;
            }
          }
        }
      }
    }
    &:hover {
      .home-menu {
        display: block;
      }
    }
  }
  .create-acc-block {
    @include create-sign;
    position: relative;
    @include breakpoints(small) {
      font-size: 12px;
    }
    .home-menu {
      @include home-menu;
      right: 7px;
      @include breakpoints(small) {
        right: -15px;
      }
      .home-menu-inner {
        @include home-menu-inner;
        @include breakpoints(small) {
          &:before {
            right: 50px;
          }
        }
        .home-menu-links {
          width: 260px;
          color: #000000;
          @include breakpoints(small) {
            width: 215px;
          }
          &:first-child {
            border-bottom: solid 1px #b9b9b9;
          }
          &.no-border {
            border-bottom: 0;
          }
        }
      }
    }
    > a {
      border: none;
      color: map-get($colors, grey-dark);
      margin-right: 20px;
      position: relative;
      &:after {
        background-position: -132px -309px;
        right: 12px;
      }
      @include breakpoints(small) {
        margin-right: 0;
      }
    }
    &:hover {
      .home-menu {
        display: block;
      }
    }
  }
  .header-practice-link {
    @include header-center-link;
  }
  .header-after-login {
    @include wrap-flx;
    justify-content: space-between;
    padding: 30px 0 26px;
    align-items: center;
    @include breakpoints(medium) {
      padding: 15px 0;
    }
    @include breakpoints(small) {
      padding: 15px 0 10px;
      flex-wrap: nowrap;
    }
    .header-logo {
      width: 30%;
      @include breakpoints(medium) {
        width: 45%;
      }
      @include breakpoints(small) {
        width: 100%;
        text-align: left;
      }
      img {
        // width: 246px;
        @include breakpoints(medium) {
          // width: 180px;
        }
      }
    }
    .header-search {
      width: 28%;
      @include breakpoints(large) {
        width: 24%;
      }
      @include breakpoints(medium) {
        width: 47%;
      }
      @include breakpoints(small) {
        width: 100%;
        margin-top: 15px;
        margin-right: 0;
      }
      position: relative;
      .search-btn {
        position: absolute;
        right: 15px;
        top: 15px;
        background-image: url(#{$imagePath}search-icon.png);
        width: 21px;
        height: 20px;
        border: 0;
        color: transparent;
        background-color: transparent;
        background-size: cover;
        @include breakpoints(medium) {
          width: 17px;
          height: 16px;
          right: 10px;
        }
      }
    }
    .mobile-menu {
      position: absolute;
      width: 30px;
      height: 30px;
      right: 0;
      top: 35px;
      z-index: 11;
      cursor: pointer;
      display: none;
      @include breakpoints(small) {
        top: 25px;
        display: block;
      }
      .burger-menu {
        width: 100%;
        height: 100%;
        cursor: pointer;
        span {
          position: absolute;
          right: 0;
          top: 0;
          height: 3px;
          background-color: map-get($colors, primary-main);
          width: 100%;
          display: block;
          @include transition(all 0.1s ease-in-out);
          @include border-radius(10px);
          &:before {
            content: "";
            position: absolute;
            right: 0;
            top: -8px;
            height: 3px;
            background-color: map-get($colors, primary-main);
            width: 100%;
            @include transition(all 0.3s ease-in-out);
          }
          &:after {
            content: "";
            position: absolute;
            right: 0;
            top: 8px;
            height: 3px;
            background-color: map-get($colors, primary-main);
            width: 100%;
            @include transition(all 0.3s ease-in-out);
          }
        }
      }
      &.bmenu-active {
        .burger-menu {
          span {
            height: 0;
            &:before {
              @include transform(rotate(45deg));
              top: 0;
            }
            &:after {
              @include transform(rotate(-45deg));
              top: 0;
            }
          }
        }
      }
    }
    .header-right-log {
      width: 33%;
      @include breakpoints(large) {
        width: 38%;
      }
      @include breakpoints(medium) {
        width: 100%;
        margin-top: 30px;
      }
      @include breakpoints(small) {
        margin-top: 0px;
        // margin-top: 15px;
        // width: 100vw;
        // position: absolute;
        // top: 0;
        // background-color: rgba($color: #ffffff, $alpha: 0.99);
        // height: 100vh;
        // z-index: 10;
        // padding-top: 100px;
        // right: -15px;
        // @include transform(translateX(100%));
        // @include transition(all 0.3s ease-in-out);
        // &.open-menu{
        // 	@include transform(translateX(0%));
        // }
        // overflow-y: auto;
      }
      .account-icons-wrap {
        @include wrap-flx;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0.5px;
        @include breakpoints(small) {
          font-size: 12px;
          line-height: 15px;
          // flex-direction: column;
          flex-wrap: nowrap;
          justify-content: flex-end;
          align-items: center;
        }
        a {
          color: map-get($colors, grey-dark);
        }
        .dashboard-sec {
          width: 145px;
          @include breakpoints(small) {
            width: auto;
            text-align: center;
          }
          .dash-board-icon {
            @include sprite-header;
            background-position: -1px -32px;
            margin: 0 auto;
            padding-bottom: 35px;
            width: 32px;
            height: 27px;
            @include breakpoints(small) {
              padding-bottom: 0;
            }
          }
          &.active {
            a {
              color: map-get($colors, primary-main);
            }
            .dash-board-icon {
              background-position: -1px -79px;
            }
          }
          &:hover {
            a {
              color: map-get($colors, primary-main);
            }
            .dash-board-icon {
              background-position: -1px -79px;
            }
          }
        }
        .notification-sec {
          @include breakpoints(small) {
            text-align: center;
            width: auto;
            // padding: 15px 0;
            // margin: 15px 0;
            // border-top: 1px solid map-get($colors , doc-box-border);
            // border-bottom: 1px solid map-get($colors , doc-box-border);
            margin-left: 25px;
            margin-right: 25px;
          }
          .notify-icon {
            @include sprite-header;
            background-position: -133px -79px;
            background-repeat: no-repeat;
            top: 0;
            left: 0;
            margin: 0 auto;
            padding-bottom: 35px;
            width: 22px;
            height: 27px;
            position: relative;
            .notification-circle {
              position: absolute;
              top: 0;
              right: -5px;
              width: 18px;
              height: 18px;
              padding-top: 3px;
              background-color: map-get($colors, error);
              color: #fff;
              text-align: center;
              font-size: 11px;
              font-weight: 500;
              @include border-radius(100%);
            }
            @include breakpoints(small) {
              padding-bottom: 0;
            }
          }
          &.active {
            a {
              color: map-get($colors, primary-main);
            }
            .notify-icon {
              background-position: -133px -32px;
            }
          }
          &:hover {
            a {
              color: map-get($colors, primary-main);
            }
            .notify-icon {
              background-position: -133px -32px;
            }
          }
        }
        .account-sec {
          position: relative;
          .home-menu {
            @include home-menu;
            top: 55px;
            right: -28px;
            @include breakpoints(large) {
              right: -4px;
            }
            @include breakpoints(small) {
              top: 35px;
              // right: 0;
              // left: -120px;
              // margin: 0 auto;
              // text-align: center;
              // width: 330px;
              // top: 0;
              // position: relative;
            }
            .home-menu-inner {
              @include home-menu-inner;
              width: 230px;
              &:before {
                @include breakpoints(large) {
                  right: 5px;
                }
                @include breakpoints(small) {
                  // right: 122px;
                }
              }
              @include breakpoints(small) {
                // width: 100%;
                // margin: 0 auto;
                // text-align: left;
              }
              a {
                @extend %myaccount-menu-font;
                display: block;
                position: relative;
                padding: 0 0 15px 30px;
                margin-bottom: 15px;
                border-bottom: 1px solid map-get($colors, silver-main);
                &:hover {
                  color: map-get($colors, primary-main);
                  &:before {
                    background-position: -53px -99.5px;
                  }
                  &:nth-child(2) {
                    &:before {
                      background-position: -3px -99.5px;
                    }
                  }
                  &:nth-child(3) {
                    &:before {
                      background-position: -6px -383px;
                    }
                  }
                }
                &:before {
                  @include sprite;
                  background-size: 415px;
                  background-position: -53px -145px;
                  width: 23px;
                  height: 24px;
                }
                &:nth-child(1) {
                  &:before {
                    // top: 4px;
                    @include breakpoints(large) {
                      // top: -2px;
                    }
                    @include breakpoints(small) {
                      // top: -4px;
                    }
                  }
                }
                &:nth-child(2) {
                  &:before {
                    top: 0;
                    background-position: -3px -145px;
                    @include breakpoints(large) {
                      // top: -4px;
                    }
                    @include breakpoints(small) {
                      // top: -8px;
                    }
                  }
                }
                &:nth-child(3) {
                  margin-bottom: 0;
                  border: 0;
                  &:before {
                    // top: 2px;
                    background-position: -6px -351px;
                    background-size: 550px;
                    @include breakpoints(large) {
                      // top: -3px;
                    }
                    @include breakpoints(small) {
                      // top: -7px;
                    }
                  }
                }
              }
            }
          }
          @include breakpoints(small) {
            width: auto;
            text-align: center;
            margin-top: 10px;
          }
          .myacc-icon {
            @include sprite-header;
            background-position: -72px -77px;
            margin: 0 auto;
            padding-bottom: 35px;
            width: 25px;
            height: 27px;
            @include breakpoints(small) {
              padding-bottom: 0;
            }
          }
          &.active {
            span {
              color: map-get($colors, primary-main);
              &:after {
                background-position: -132px -331px;
              }
            }
            .myacc-icon {
              background-position: -72px -30px;
            }
          }
          &:hover {
            .home-menu {
              display: block;
            }
            span {
              color: map-get($colors, primary-main);
              &:after {
                background-position: -132px -331px;
              }
            }
            .myacc-icon {
              background-position: -72px -30px;
            }
          }
          .menu-arrow-icon {
            padding-right: 15px;
            color: map-get($colors, grey-dark);
            cursor: pointer;
            position: relative;
            &:after {
              @include sprite;
              background-position: -132px -310px;
              top: auto;
              bottom: 4px;
              right: 0;
              left: auto;
              width: 13px;
              height: 8px;
              @include breakpoints(small) {
                bottom: 2px;
                right: -1px;
                display: none;
              }
            }
          }
          @supports (-moz-appearance: none) {
            .menu-arrow-icon {
              &:after {
                bottom: -46px;
                right: -96px;
                @include breakpoints(small) {
                  bottom: -45px;
                  right: -44px;
                }
              }
            }
          }
        }
      }
    }
  }
  .header-search {
    @include breakpoints(small) {
      position: relative;
      width: 100%;
      margin-top: 15px;
      margin-right: 0;
      .input-search {
        margin-bottom: 15px;
      }
      .search-btn {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        background-image: url(#{$imagePath}search-icon.png);
        width: 21px;
        height: 20px;
        border: 0;
        color: transparent;
        background-color: transparent;
        background-size: cover;
        @include breakpoints(medium) {
          width: 17px;
          height: 16px;
          right: 10px;
        }
      }
    }
  }
}
.menu-name {
  @include breakpoints(small) {
    display: none;
  }
}
.overflowy-hide {
  overflow-y: hidden;
  // position: fixed;
}
.cookie-concent-wrapper {
  background-color: #eff2fc;
  padding: 30px;
  padding-left: 70px;
  padding-right: 70px;
  font-size: 15px;
  transition: all 1s;
  visibility: visible;
  &.hidden {
    width: 0px;
    visibility: hidden;
    padding: 0px;
    height: 0;
  }
  & .next-prev-btn-pro {
    text-align: right;
    & .next-btn-pro {
      padding-left: 20px;
      padding-right: 20px;
      &:hover {
        color: #fff;
      }
    }
    @include breakpoints(small) {
      text-align: center;
    }
  }
  @include breakpoints(medium) {
    padding: 30px;
  }
}
