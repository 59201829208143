.patient-management-outer{
    .report-btn{
        display: none !important;
    }
    .patient-details-outer{
        .table-report{
            .table-sort-filter{
                margin-top: 0;
                .filter-right-wrap{
                    display: none;
                }
            }
        }
        .rating{
            margin-right: 0;
        }
        .form-add-link{
            font-size: 13px;
        }
        .medical-info{
            text-align: left;
            &:before{
                display: none;
            }
            span{
                padding: 0;
            }
        }
        .appoint-req-box{
            .info-icon{
                display: none;
            }
        }
        .next-prev-btn-pro{
            justify-content: flex-start;
            .prev-btn-pro, .next-btn-pro{
                font-size: 14px;
                width: 95px;
                padding: 10px 0;
            }
            .prev-btn-pro{
                &:hover{
                    background-color: map-get($colors, white1);
                }
            }
        }
    } 
    .bookbtn-wrap{
        @include wrap-flx;
        flex-wrap: nowrap;
        align-items: center;
        .bookbtn{
            display: block;
            color: map-get($colors, white1);
            background-color: map-get($colors, primary-main);
            @extend %scheduling-page-font;
            @include border-radius(4px);
            width: 150px;
            font-weight: 300;
            text-align: center;
            padding: 8px 0;
            margin-right: 15px;
        }
        .delicon{
            img{
                width: 16px;
            }
        }
    }   
}
.admin-modal-box{
    width: 740px;
    padding: 0 20px 30px;
    .provider-form-edit{
        .select{
            @include border-radius(0);
        }
        .select-option{
            .select-option-icon{
                right: 20px;
            }
        }
        .textarea-input{
            font-size: 14px;
            height: 90px;
        }
    }
}
.redtext{
    color: map-get($colors, delete-color);
}
.admin-appoint-cal{
    max-width: 580px;
    margin: 0 0 0 10px;
    @include breakpoints(small){
        margin: 0;
    }
    .scheduling-component-main{
        .date-area{
            .date-column{
                .date-heading{
                    span{
                        font-size: 14px;
                    }
                }
                .date-list{
                    font-weight: 500;
                }
            }
        }
    }
}

.search-modal-wrap.patient-info-modal{
    padding: 30px 0 0;
    @include breakpoints(small){
        padding: 20px 0 0;
    }
    .edit-all-filter{
        max-width: 600px;
    }
    .demo-medical-wrap{
        .demo-left, .medical-right{
            width: 100%;
        }
        .demo-left{
            margin-bottom: 50px;
            @include breakpoints(small){
                margin-bottom: 30px;
            }
        }
    }
}

.provider-form-edit{
    margin-top: 0;
}