.profile-review-box {
  @include box-shadow-style;
  padding: 30px;
  margin-bottom: 50px;
  @include breakpoints(small) {
    padding: 15px;
    margin-bottom: 30px;
  }
  .review-filter-selection {
    display: flex;
    justify-content: space-around;
    .source {
      display: flex;
      width: 45%;
      justify-content: space-between;
      select {
        width: 70%;
        padding: 10px;
      }
    }
    .rating-filter {
      display: flex;
      width: 45%;
      justify-content: space-between;
      select {
        width: 70%;
        padding: 10px;
      }
    }
    @include breakpoints("medium") {
      flex-direction: column;
      .source {
        margin-top: 20px;
        // margin-bottom: 20px;
        width: 100%;
        select {
          width: 85%;
        }
      }
      .rating-filter {
        margin-top: 20px;
        // margin-bottom: 20px;
        width: 100%;
        select {
          width: 85%;
        }
      }
    }
    @include breakpoints("small") {
      flex-direction: column;
      .source {
        margin-top: 10px;
        width: 100%;
        select {
          width: 70%;
        }
      }
      .rating-filter {
        margin-top: 10px;
        width: 100%;
        select {
          width: 70%;
        }
      }
    }
  }
  .write-review {
    position: absolute;
    right: 30px;
    top: 15px;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.7px;
    background-color: map-get($colors, write-review);
    @include border-radius(3px);
    @include breakpoints(small) {
      font-size: 14px;
      right: 10px;
      top: 10px;
    }
    a {
      color: map-get($colors, write-review-txt);
      padding: 13px 40px 13px 60px;
      display: block;
      @include breakpoints(small) {
        padding: 8px 20px 8px 35px;
      }
      span {
        position: relative;
        &:before {
          @include profile-icons;
          width: 34px;
          height: 18px;
          background-position: 0 -340px;
          left: -25px;
          @include breakpoints(small) {
            top: -3px;
          }
        }
      }
    }
    &.review-enable {
      background-color: map-get($colors, primary-main);
      a {
        color: map-get($colors, white1);
        span {
          &:before {
            background-image: none;
            content: "\2605";
            top: -5px;
            font-size: 20px;
            @include breakpoints(medium) {
              top: -8px;
            }
          }
        }
      }
    }
  }
  .review-text-container {
    padding: 0 30px 0 0;
    margin: 40px 0 0;
    overflow: auto;
    max-height: 450px;
    overflow-x: hidden;
    @include breakpoints(medium) {
      padding: 0 15px 0 0;
    }
    @include breakpoints(small) {
      margin-top: 20px;
    }
    ul {
      li {
        @include reg-font;
        color: map-get($colors, black1);
        border-bottom: 2px solid #e5e5e5;
        padding-bottom: 25px;
        margin-bottom: 25px;
        div {
          &:first-child {
            font-weight: 700;
          }
        }
        .rating-result {
          margin: 8px 0 5px;
        }
      }
    }
    &::-webkit-scrollbar {
      width: 18px;
      @include breakpoints(medium) {
        width: 14px;
      }
    }
    &::-webkit-scrollbar-track {
      background-color: #e3e3e3;
      padding: 0 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #fff;
      cursor: pointer;
      width: 10px;
      border: 4px solid #e3e3e3;
      @include breakpoints(medium) {
        width: 6px;
        border: 3px solid #e3e3e3;
      }
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #077db4;
    }
    .rating-source {
      font-size: 14px;
      color: map-get($colors, grey-dark);
    }
  }
}
