.covid19-strip {
  color: red;
  @extend %h1;

  @include breakpoints(small) {
    color: map-get($colors, primary-main);
  }
  @include breakpoints(medium) {
    color: black;
  }
}