.footer {
  background-color: map-get($colors, primary-main);
  color: map-get($colors, white1);
  padding: 50px 0 15px;
  .footerLink {
    display: initial;
    padding: 0px;
  }
  .footer-inner {
    h4 {
      font-weight: 500;
      letter-spacing: 1px;
      margin-bottom: 15px;
      font-size: 16px;
    }
    ul {
      li {
        font-weight: 300;
        font-size: 14px;
        letter-spacing: 1px;
        line-height: 35px;
        font-family: $roboto;
        @include breakpoints(medium) {
          font-size: 14px;
          line-height: 30px;
        }
        a {
          color: map-get($colors, white1);
          position: relative;
          text-decoration: none;
          &:after {
            content: "";
            position: absolute;
            bottom: -4px;
            left: 0;
            height: 1px;
            width: 0%;
            background-color: map-get($colors, white1);
            transition: all 0.2s ease-in-out;
          }
          &:hover {
            &:after {
              width: 100%;
            }
          }
        }
      }
    }
    .list-your-doc {
      background-color: map-get($colors, white1);
      border-radius: 5px;
      -webkit-border-radius: 5px;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0.8px;
      width: 227px;
      text-align: left;
      float: left;
      @include breakpoints(medium) {
        float: none;
      }
      @include breakpoints(small) {
        width: 100%;
      }
      a {
        color: map-get($colors, primary-main);
        position: relative;
        display: block;
        padding: 12px 12px 12px 57px;
        line-height: normal;
        &:before {
          @include sprite;
          left: 12px;
          width: 36px;
          height: 36px;
          top: 11px;
          background-position: -442px -252px;
        }
      }
    }
    @include breakpoints(medium) {
      h4 {
        margin-top: 0;
      }
      .nav1 {
        h4 {
          &.accord {
            cursor: pointer;
            padding: 15px 0;
            text-align: left;
            // border-bottom: 1px solid rgba(255, 255, 255, 0.3);
            margin: 0 0 10px 0;
          }
        }
      }
    }
    // &:first-child {
    //     @include breakpoints('xlarge') {
    //         padding-left: 0px;
    //     }
    // }
    // &:last-child {
    //      @include breakpoints('xlarge') {
    //         padding-right: 0px;
    //     }
    // }
    &.accordion-group {
      .nav1 {
        @include breakpoints(medium) {
          border-bottom: 1px solid #188dc4;
        }
        margin-bottom: 10px;
        padding-bottom: 10px;
        .panel-title {
          &.accord {
            &:after {
              font-size: 30px;
              font-weight: 400;
            }
            &.active {
              &:after {
                content: "_";
                top: -3px;
              }
            }
          }
        }
      }
    }
  }
  .footer-bott-txtwrap {
    max-width: 770px;
    margin: 0 auto;
    text-align: center;
    @include breakpoints(small) {
      padding: 0 10%;
    }
    .footer-logo {
      text-align: center;
      margin: 50px auto 15px;
      max-width: 260px;
      .logo-main {
        justify-content: center;
        span {
          color: map-get($colors, white1);
          font-size: 30px;
          line-height: 30px;
          letter-spacing: 0.2px;
          margin-left: 2px;
        }
      }
      @include breakpoints(medium) {
        margin-top: 30px;
      }
      @include breakpoints(small) {
        margin-top: 47px;
      }
    }
    > p {
      font-size: 12px;
      padding: 0;
      margin: 0;
      line-height: 1.5;
      letter-spacing: 0.8px;
      margin: 0 auto 15px;
      font-weight: 300;
      @include breakpoints(medium) {
        font-size: 11px;
      }
    }
  }
}
