$colors : (
'primary-main' :#077db4,
'black_color' : #000000,
'white1' : #ffffff,
'border-grey' :#dbdee9,
'grey-lighter':#e6e6e6,
'grey-light':#c9c9c9,
'grey-dark':#606060,
'light-gray': #d1d1d1,
'light-grayish-blue':#e4eef3,
'white-smoke':#f4f4f4,
'green-shade':#38b800,
'rev-chk-label':#404040,
'black1' : #000000,
'grey-plan' : #f4f4f4,
'error':#f91717, 
'home-top': #f9f9f9,
'filter-bg': #f6f6f6,
'filter-color':#091f29,
'filter-border':#7b7b7b,
'doc-box-border':#dcdcdc,
'profile-border': #f0f0f0,
'link-endorse':#3897f0,
'top-doc':#3897f0,
'write-review':#dfe0e1,
'write-review-txt':#dbcfcf,
'ford-gray': #979797,
'light-green': #76d186,
'light-red': #de5646,
'link-hover':#e0f5ff,
'modal-border':#e5e5e5,
'cultured-pearl': #f7f6f6,
'matterhorn': #505050,
'darkshade-gray': #444444,
'alice-blue':#f7fafb,
'night-rider': #313131,
'delete-color':#d93025,
'right-tick':#20ba0e,
'success-green': #20ba0e,
'banana-mania': #ffecb3,
'silver-main': #c1c1c1,
'alice-blue-bg': #e7f8ff,
'hg-input':#808080,
'hg-table-head':#d4effc,
'grade-a':#066fa0,
'grade-b':#098bc8,
'grade-c':#09a6ef,
'grade-d':#1db7ff,
'grade-e':#45c2fc,
'grade-f':#9fe0ff,
'grade-border':#c3c3c3,
'result-active':#b8d7e9,
'bright-gray': #ececec,
'cookiesbg':#eff2fa,
'covid-red':#eba2a0,
'covid-yellow':#ffecb3,
'covid-green':#cbecba,
'fbcolor':#375797,
'twitter-color':#1ba6f9,
'thbg':#f5f6f9,
'thcolor':#222222,
'greyborder':#f3f3f3,
'thblue':#e2f2f9,
'radio-tick':#aaaaaa,
'percent':#aeaeae,
'disabled':#9f9f9f,
'danger':#b40727,
'select':#757575,
)