.content-management-outer{
    margin: 30px 0;
    width: 100%;
    @include breakpoints(small){
        margin-bottom: 10px;
        margin-top: 20px;
    }
    .content-mange-head-wrap{
        background-color: map-get($colors, thbg);
        @include wrap-flx;
        justify-content: space-between;
        padding: 20px 30px;
        @include breakpoints(large){
            padding: 20px 0px 20px 20px;
        }
        @include breakpoints(small){
            padding: 20px 0px 20px 10px;
        }
        .content-head{
            @extend %th-text-font;
            &:first-child{
                width: 70%;
                @include breakpoints(small){
                    width: 45%;
                }
            }
            &:last-child{
                width: 30%;
                text-align: center;
                @include breakpoints(small){
                    width: 50%;
                }
            }
        }
    }
    .content-manage-outer{
        border-bottom: 1px solid map-get($colors, greyborder);
        border-left: 1px solid map-get($colors, greyborder);
        border-right: 1px solid map-get($colors, greyborder);
        .content-manage-inner{        
            @include wrap-flx;
            justify-content: space-between;
            padding: 20px 30px;            
            align-items: center;
            height: 62px;
            padding: 21px 36px 20px 30px;
            @include breakpoints(large){
                height: inherit;
                padding: 20px 0px 20px 20px;
            }
            @include breakpoints(small){
                height: inherit;
                padding: 20px 0px 20px 10px;
            }
            .content-style{            
                &:first-child{
                    width: 70%;
                    @extend %content-manage-text-font;
                    @include breakpoints(small){
                        width: 45%;
                    }
                }
                &:last-child{
                    width: 30%;
                    font-size: 14px;
                    letter-spacing: 0.5px;
                    text-align: center;
                    @include breakpoints(small){
                        width: 50%;
                        text-align: left;
                        margin-top:10px;
                    }
                }
                .bottom-btns{
                    display: none;
                    .next-prev-btn-pro{
                        .prev-btn-pro, .next-btn-pro{
                            font-weight: 400;
                            height: 35px;
                            @include breakpoints(large){
                                width: 70px;
                            }
                        }
                        .prev-btn-pro{
                            @include breakpoints(small){
                                margin-right: 5px;
                            }
                        }
                        .subscription-btn, .website-content-btn{
                            padding:0px !important;
                        }
                    }
                }
            }
            &.highlight{
                background-color: map-get($colors, thblue);
                height: 56px;
                padding: 11px 71px 10px 30px;
                @include breakpoints(small){
                    height: inherit;
                    padding: 20px 0px 20px 10px;
                }
                a{
                    display: none;
                }
                .bottom-btns{
                    display: block;
                }
            }
            &.highlight-subscription{
                .subscription-input{
                    width: 346px;
                    height: 48px;
                    border-radius: 4px;
                    border: solid 1px #979797;
                    background-color: #ffffff;
                    font-size: 18px;
                    padding: 14px 11px;
                    margin: 0px -10px;
                    @include breakpoints(small){
                        margin: 0px;
                    }
                }
                .content-style{
                    span{
                        border: 1px solid map-get($colors, ford-gray);
                        @include border-radius(4px);
                        max-width: 345px;
                        display: block;
                        padding: 12px;
                    }
                }
                a{
                    display: none;
                }
                .bottom-btns{
                    display: block;
                }
            }
        }
        .content-manage-detail{
            padding: 20px 30px;
            @include breakpoints(large){
                padding: 20px;
            }
            @include breakpoints(small){
                padding: 20px 0;
            }
            .paragraph-wrap{
                border: 1px solid map-get($colors, greyborder);
            }
            .meta-descr-box{
                padding: 0;
                .pro-label{
                    @extend %content-manage-text-font;
                }
                .input-pro{
                    border-color:map-get($colors, greyborder);
                }
            }
            .editor-class{
                height: 220px;
                padding: 0 20px;
                @extend %scrollbarDesign1;
            }
            .rdw-dropdown-wrapper{
                height: 20px;
            }
            .rdw-editor-toolbar{
                font-size: 13px;
            }
            .rdw-dropdown-selectedtext{
                color: map-get($colors, black1);
            }
            .rdw-fontfamily-wrapper, .rdw-emoji-wrapper{
                display: none;
            }            
        }
    }
}

.billing-detail-subscription{
    margin: 60px 0 40px;
    @include breakpoints(small){
        margin: 40px 0 20px;
    }
    .billing-detail-inner-tophead, .billing-detail-body{
        @include wrap-flx;
        background-color: map-get($colors, thbg);
        padding: 12px 0 12px 30px;  
        margin: 0px 20px;
        border-radius: 4px;
        @include breakpoints(small){
            padding: 12px 0 12px 10px;
            margin: 0px;
        }
        .billing-body, .billing-head{
            font-weight: 500;
            span{
                display: block;
            }
            &:nth-child(1){
                width: 195px;
                margin-right: 50px;
                @include breakpoints(small){
                    width: 37%;
                    margin-right: 10px;
                }
            }
            &:nth-child(2){
                width: 125px;
                margin-right: 50px;
                @include breakpoints(small){
                    width: 32%;
                    margin-right: 10px;
                }
                .table-sort-filter{
                    .input-pro{                        
                        @include breakpoints(small){
                            padding-left: 18px;
                        }
                    }
                }
            }
            &:nth-child(3){
                width: 115px;
                @include breakpoints(small){
                    width: 24%;
                }
                .table-sort-filter{
                    .input-pro{
                        padding-right: 30px;
                        @include breakpoints(small){
                            padding-left: 8px;
                        }
                    }
                }                
            }
            .table-sort-filter{
                position: relative;
                width: 100%;
                margin: 0;  
                .sort-select{
                    max-width: 100%;
                }              
                .input-pro{
                    border-color: map-get($colors, ford-gray);
                    height: 48px;
                    @include border-radius(4px);   
                    padding-left: 12px;   
                    font-weight: 500;
                    color: map-get($colors, black1);  
                    font-size: 16px;    
                    padding: 10px 12px 14px;                                      
                    &.sort-select{
                        padding: 0 0 0 12px;
                    } 
                    @include breakpoints(small){
                        font-size: 14px;
                        &.sort-select{
                            padding: 0 0 0 8px;
                        } 
                    }        
                }
                .percent{
                    position: absolute;
                    right: 14px;
                    top: 50%;
                    transform: translateY(-50%);
                    color: map-get($colors, percent);  
                    font-size: 18px;
                    @include breakpoints(small){
                        right: 8px;
                    }
                }
                .symbol{
                    position: absolute;
                    left: 14px;
                    top: 50%;
                    transform: translateY(-50%);
                    color: map-get($colors, black1); 
                    font-weight: 500;
                    @include breakpoints(small){
                        left: 8px;
                    }
                }
            }
        }       
    } 
    .billing-detail-body{
        background-color: map-get($colors, white1);
        padding: 0;
        margin: 15px 0 20px 50px;
        @include breakpoints(small){
            margin: 15px 0 20px 10px;
        }
    }
    
    .billing-period{
        width: 195px;
        height: 48px;
        @include breakpoints(small){
            width: inherit;
        }
    }
    .billing-price{
        width: 126px;
        height: 48px;
        @include breakpoints(small){
            width: inherit;
        }
    }
    .billing-discount{
        width: 112px;
        height: 48px;
        padding:10px 14px;
        @include breakpoints(small){
            width: inherit;
        }
    }
}