.youtube-video-component {
    margin: 40px auto; 
    padding-top: 47px;
    padding-bottom: 47px;
    border-radius: 6px;
    background-color: map-get($colors, alice-blue);
    h3 {
        font-size: 28px;
        font-weight: bold;
        letter-spacing: 1.17px;
        @include breakpoints(small) {

        }
    }
    .video-container {
        margin: 40px auto;
        iframe {
            width: 100%;
            height: 531px;
            @include breakpoints(large) {
                height: 67vh;
            }
            @include breakpoints(medium) {
                height: 76vh;
            }
            @include breakpoints(small) {
                height: 48vh;
            }
        }
    }
    .submission {
        display: flex;
        max-width: 376px;
        justify-content: center;
        margin: 20px auto;
        a {
            color: map-get($colors, white1);
            margin-right: 5px;
        }
        @include breakpoints('small') {
            width:80%;
        }
    }
}
