.health-grade-wrap {
  h2 {
    font-weight: 500;
    letter-spacing: 1px;
    margin-bottom: 20px;
  }
  .health-grade-right {
    padding-left: 50px;
    @include breakpoints(medium) {
      padding-left: 0;
    }
  }

  .health-grade-inner {
    @include box-shadow-style;
    @include border-radius(10px);
    padding: 30px 40px;
    @include breakpoints(medium) {
      padding: 20px;
    }
    @include breakpoints(medium) {
      padding: 15px;
    }
    h2 {
      margin-bottom: 25px;
    }
    .hgrade-border {
      border-right: 1px solid #979797;
      @include breakpoints(medium) {
        border: 0;
        border-bottom: 1px solid #979797;
        margin-bottom: 20px;
        padding-bottom: 20px;
      }
    }
    .calc-heading {
      @extend %calc-heading-font;
      margin-bottom: 13px;
      line-height: 1.2;
    }
    .health-grade-field-outer {
      margin-bottom: 25px;
      .health-grade-field,
      .health-grade-field-result,
      .health-grade-field-advance {
        @include wrap-flx;
        align-items: flex-end;
        max-width: 100%;
        @extend %hg-label-font;
        color: map-get($colors, grey-dark);
        div {
          margin-right: 30px;
          margin-right: 10px;
          margin-bottom: 10px;
          &:first-child {
            width: 50%;
            @include breakpoints(small) {
              width: 100%;
              margin-bottom: 20px;
            }
          }
          &:last-child {
            margin-right: 0;
          }
        }
        span {
          display: block;
          margin-bottom: 5px;
        }
        .input-pro {
          width: 100px;
          text-align: center;
          @extend %hg-fields-font;
          color: map-get($colors, hg-input);
          // height: 45px;
          @include breakpoints(small) {
            width: 80px;
          }
        }
        small {
          font-size: 100%;
          margin-left: 9px;
        }
      }
      .health-grade-field-result,
      .health-grade-field-advance {
        @extend %hg-label-font;
        color: map-get($colors, black1);
        align-items: flex-start;
        font-weight: 400;
        div {
          width: 50%;
          margin: 0;
          @include breakpoints(small) {
            width: 100%;
          }
          span {
            display: block;
          }
          &:last-child {
            text-align: center;
            .info-callout {
              .callout-inner {
                width: 100%;
                text-align: left;
                @include breakpoints(medium) {
                  height: 120px;
                  overflow-y: scroll;
                }
              }
              width: 490px;
              @include breakpoints(medium) {
                width: 350px;
                text-align: left;
                left: auto;
                right: -90px;
                top: auto;
                bottom: 45px;
                &:after {
                  top: auto;
                  left: auto;
                  bottom: -8px;
                  padding: 7px;
                  right: 93px;
                  @include transform(rotate(45deg));
                }
              }
              @include breakpoints(small) {
                width: 280px;
              }
            }
            span {
              display: inline;
              &.info-hg {
                position: absolute;
              }
            }
            .green-right {
              width: 32px;
              margin: 0 auto;
              &:after {
                height: 33px;
                top: 0;
              }
            }
          }
        }
        .result-highlight {
          background-color: map-get($colors, result-active);
          width: 240px;
          padding: 3px 0 3px 10px;
          font-weight: 700;
        }
        .result-italic {
          font-weight: 700;
          font-style: italic;
        }
        .result-red-mark {
          position: relative;
          width: 100%;
          height: 28px;
          &:before {
            position: absolute;
            content: "!";
            color: map-get($colors, delete-color);
            font-weight: 700;
            font-size: 45px;
            line-height: 40px;
          }
        }
        .hg-result-text {
          @extend %hg-result-font;
          color: map-get($colors, grey-dark);
          width: 100%;
          margin-top: 20px;
          position: relative;
        }
      }
      .health-grade-field-advance {
        align-items: center;
      }
    }
    .hg-calc-button {
      .next-btn-pro {
        width: 220px;
        margin-top: 10px;
      }
      .switch-button-hg {
        display: block;
        @extend %hg-label-font;
        color: map-get($colors, primary-main);
        letter-spacing: 0.4px;
        margin-top: 25px;
        cursor: pointer;
        line-height: 1;
      }
    }
    .share-wrap {
      @extend %hg-label-font;
      color: map-get($colors, grey-dark);
      font-size: 18px;
      margin: 10px 0 15px;
      font-weight: 700;
      letter-spacing: 0.75px;
    }
    .social-icon-hg {
      a {
        &:first-child {
          margin-right: 20px;
        }
      }
    }
    .health-grade-circle {
      margin: 55px auto 0;
      width: 100%;
      @include breakpoints(medium) {
        margin-top: 45px;
      }
    }
    // Health grade history
    .hg-history-wrap {
      @include box-shadow-style;
      @include box-shadow(none);
      margin-top: 40px;
      padding: 25px;
      @include breakpoints(small) {
        padding: 5px;
      }
      .hg-history-inner {
        overflow: hidden;
        .table-heading-hg {
          background-color: map-get($colors, hg-table-head);
          padding: 15px 20px;
          @extend %hg-label-font;
          color: map-get($colors, grey-dark);
          div {
            font-weight: 500 !important;
          }
        }
        .table-body-hg {
          @include wrap-flx;
          justify-content: space-between;
          align-items: center;
          padding: 6px 20px;
          width: 1250px;
          border-bottom: 1px solid map-get($colors, grade-border);
          &:first-child,
          &:last-child {
            border-bottom: 0px;
          }
          div {
            width: 5%;
            @extend %hg-label-font;
            color: map-get($colors, grey-dark);
            font-weight: 400;
            &:first-child {
              width: 10%;
            }
            &:nth-child(2) {
              width: 13%;
            }
            &:nth-child(3) {
              width: 8%;
            }
            &:nth-child(4) {
              width: 7%;
            }
            &:nth-child(5) {
              width: 13%;
            }
            &:nth-child(6) {
              width: 11%;
            }
            &:nth-child(7) {
              width: 15%;
            }
            &:nth-child(8) {
              width: 18%;
            }
            &:last-child {
              button {
                color: map-get($colors, primary-main);
                background: none;
                border: none;
                outline: none;
              }
            }
          }
        }
        .health-grade-table {
          height: 230px;
          overflow: scroll;
          @extend %scrollbarDesign1;
        }
      }
    }
  }
}

.info-hg {
  position: relative;
  top: 2px;
  margin-left: 5px;
  cursor: pointer;
  &.right {
    width: max-content;
    margin-left: auto;
  }
  @include breakpoints(small) {
    top: 2px;
  }
  .info-callout {
    @include callout;
    width: 330px;
    left: 35px;
    right: auto;
    top: -20px;
    p {
      padding: 0;
      margin: 0 0 10px 0;
      &:last-child {
        margin-bottom: 0;
      }
    }
    ul {
      margin-top: 5px;
      li {
        list-style-type: disc;
        list-style-position: outside;
        margin: 0 0 0 15px;
      }
    }
    @include breakpoints(small) {
      width: 70vw;
      left: auto;
      font-size: 11px;
      right: -85px;
      top: auto;
      bottom: 40px;
    }
    &:after {
      @include transform(rotate(135deg));
      top: 20px;
      left: -8px;
      bottom: auto;
      padding: 7px;
      @include breakpoints(small) {
        top: auto;
        left: auto;
        bottom: -8px;
        padding: 7px;
        right: 88px;
        @include transform(rotate(45deg));
      }
    }
    &.right {
      text-align: left;
      left: -350px;
      &:after {
        @include transform(rotate(-45deg));
        top: 20px;
        left: 98%;
        bottom: auto;
        padding: 7px;
        @include breakpoints(small) {
          top: auto;
          left: auto;
          bottom: -8px;
          padding: 7px;
          right: 88px;
          @include transform(rotate(45deg));
        }
      }
      @include breakpoints(small) {
        right: 0;
        left: auto;
      }
    }
    &.left-right {
      left: 52px;
      right: 0;
      top: -4px;
      min-height: 48px;
    }
    &.left-right-1 {
      left: 36px;
      right: 0;
      top: -20px;
      min-height: 48px;
    }
    &.bottom-left {
      left: -100%;
      right: 0;
      top: 30px;
      min-height: 59px;
      min-width: 230px;
      text-align: left;
      &:after {
        @include transform(rotate(233deg));
        top: -8px;
        left: 88%;
        bottom: auto;
        padding: 7px;
        @include breakpoints(small) {
          &.positive {
            top: -8px;
            left: 46%;
            bottom: auto;
            padding: 7px;
            @include transform(rotate(202deg));
          }
          // top: auto;
          // left: auto;
          // bottom: -8px;
          // padding: 7px;
          // right: 88px;
          // @include transform(rotate(45deg));
        }
      }
      &.post {
        @include breakpoints(small) {
          left: 0;
        }
        &:after {
          @include breakpoints(small) {
            top: -8px;
            left: 46%;
            bottom: auto;
            padding: 7px;
            @include transform(rotate(202deg));
          }
        }
      }
    }
  }
  &:hover {
    .info-callout {
      display: block;
    }
  }
}

.circular-chart {
  display: block;
  margin: 0px auto 35px;
  width: 290px;
  max-height: 100%;
  @include breakpoints(small) {
    width: 230px;
  }
  .circle-bg {
    fill: none;
    stroke: #eee;
    stroke-width: 2.8;
  }
  .circle {
    fill: none;
    stroke-width: 2.8;
    stroke-linecap: round;
    &.animate {
      animation: progress 3s ease-out forwards;
    }
  }
  &.blue {
    .circle {
      // stroke: map-get($colors, primary-main);
    }
  }
  .percentage {
    fill: map-get($colors, primary-main);
    font-size: 11px;
    text-anchor: middle;
    font-weight: 500;
  }
}
@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}
