.how-works-wrap{
    @include box-shadow-style;
    @include border-radius(10px);
    margin: 30px 0 70px;
    padding: 30px 50px 50px;
    @include breakpoints(medium){
        padding: 20px 20px 30px;
    }
    @include breakpoints(small){
        padding: 15px;
        margin-bottom: 40px;
    }
    .category-wraps-outer{
        @include wrap-flx;
        justify-content: space-between;
        margin: 50px auto 30px;
        text-align: center;
        border-bottom: 1px solid #e5e5e5;
        padding-bottom: 50px;
        @include breakpoints(small){
            margin: 30px auto 20px;
        }
        .category-box{
            padding: 0;
            max-width: 260px;
            margin: 0 auto;
            @include breakpoints(small){
                margin-bottom: 30px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
            .cat-text-top{
                font-size: 30px;
                color: map-get($colors, black1);
                font-weight: 700;
                margin-bottom: 20px;
            }
            .cat-text-bottom{
                font-size: 20px;
                color: map-get($colors, black1);
                font-weight: 700;
                margin-top: 20px;
                @include breakpoints(medium){
                    font-size: 16px;
                }
                @include breakpoints(small){
                    font-size: 18px;
                }
            }
            .catimg{
                width: 180px;
                margin: 0 auto;
                @include breakpoints(medium){
                    width: 150px;
                }
                @include breakpoints(small){
                    width: 180px;
                }
                img{
                    width: 100%;
                }
            }
        }
    }
    .youtube-video-component{
        background: white;
        margin: 0 auto;
        padding-top: 0;
        padding-bottom: 0;
        .video-container{
            margin-bottom: 0;
        }
    }
}