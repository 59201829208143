.create-acc-steps-wrap{
    .create-acc-inner-step1{
        max-width: 528px;
        margin: 0 auto;
    }
    .create-acc-inner-step2{
        max-width: 738px;
        margin: 0 auto;
        &.create-acc-inner-step3{
            max-width: 758px;
        }
        .radio-gender {
            .cus-radio{
                &:nth-child(3){
                    margin-left: 40px;
                    @include breakpoints(small){
                        margin: 0;
                    }
                }
                @include breakpoints(small){
                    margin: 0;
                    width: 100%;
                    margin-bottom: 10px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
        }
        .ca3-add-links {
            padding: 0;
            margin: 0;
            span {
                display: block;
                a {
                    color:map-get($colors, primary-main);
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 2;
                    @include breakpoints(small){
                        font-size: 14px;
                    }
                    &:hover{
                        text-decoration: none;
                    }
                }
                a.under-line{
                    &:after{
                        height:2px;
                        background-color:map-get($colors, primary-main);
                    }
                }
            }
        }
        .cus-check{
            font-size: 15px;
            font-weight: 500;
            color: map-get($colors, grey-dark);
            @include breakpoints(small){
                font-size: 13px;
                label{
                    margin-left: 30px;
                    &:before{
                        margin-left: -30px;
                    }
                }
                input{
                    &:checked{
                        +label{
                            &:after{
                                margin-left: -30px;
                            }
                        }
                    }
                }
            }
        } 
        .myform{
            @include select-style;
        }
    }
    .pass-rules{
        font-size: 12px;
        margin-top: 5px;
    }
    .next-btn-pro{
        max-width: 100%;
        margin-top: 10px;
    }
    .seaprator-title{
        font-size: 16px;
        margin-bottom: 20px;
        span{
            .lock {
                position: relative;
                margin: 0 6px 0 0;
            }
        }
    }   
    .bday-use {
        font-size: 13px;
        text-align: right;
        width: 100%;
        margin: 3px 0 0;
        a {
            color: #077db4;
            position: relative;
        }
    } 
}