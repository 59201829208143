.free-profile-component {
    @include box-shadow-style;
    padding: 38px 38px;
    border-radius: 8px;
    h3 {
        font-size: 24px;
        font-weight: 500;
        letter-spacing: 0.57px;
        @include breakpoints( small ) {
            font-size: 16px;
        }
    }
    .date-container {
        position: relative;
        text-align: center;
        height: 80px;
        padding: 0;
        margin-bottom: 10px;
        .heading-txt {
            display: flex;
            flex-direction: column;
            position: absolute;
            left: 0;
            bottom: 0;
            width: max-content;
            span {
                font-size: 18px;
                line-height: 1.44;
                letter-spacing: 0.75px;
                font-weight: bold;
                &:last-child {
                    font-weight: normal;
                }
                @include breakpoints(small) {
                    font-size: 12px;
                }
            }
            &:nth-child(2) {
                left: 65%;
                transform: translateX(-50%);
                @include breakpoints('small') {
                    left: 55%;
                }
            }
            &:nth-child(3) {
                left: 89%;
                transform: translateX(-50%);
                @include breakpoints('small') {
                    left: 86%;
                }
            }
        }
    }
    .bar-container {
        display:flex; 
        position: relative;
        height: 74px;
        width: 100%;
        padding: 0;
        border-radius: 3px;
        margin-bottom: 80px;
        @include breakpoints(small) {
            height: 65px;
        }
        .remaining-days {
            
            width: 65%;
            // background-color: map-get($colors, light-green);
            border: solid 1px map-get($colors, matterhorn);
            border-right: 0px;
            border-radius: 3px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            @include breakpoints('small') {
               width: 55%;
            }
            & .fill-color {
                width: 100%;
                height: 100%;
                background-color: map-get($colors, light-green);
                animation: mymove 2s;
            }
        }
        
        .billing-days {
            width: 35%;
            background-color: map-get($colors, cultured-pearl);
            border: solid 1px map-get($colors, matterhorn);
            border-left: 0;
            border-radius: 3px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            @include breakpoints('small') {
                width: 45%;
            }
        }
        .billing-circle {
            position: absolute;
            width: 58px;
            height: 58px;
            background-color: map-get($colors, darkshade-gray);
            border-radius: 50%;
            left: 65%;
            top: 50%;
            transform: translate(-29px ,-29px);
            &.last {
                left: 89%;
            }
            @include breakpoints('small') {
                width: 42px;
                height: 42px;
                left: 55%;
                transform: translate(-21px ,-21px);
                &.last {
                    left: 86%;
                }
            }
        }
        
        .msg-container {
            position: absolute;
            left: 55%;
            
            .timeline {
                height: 88px;
                margin-bottom: 10px;
                width: 1px;
                border: 3px solid;
                margin-left: 10px;
            }
            .msg {
                img {
                    margin-right: 8px;
                    position: relative;
                    top: 3px;
                }
                // display: flex;
                font-size: 18px;
                font-weight: 500;
                line-height: 1.44;
                letter-spacing: 0.75px;
            }
            @include breakpoints('small') {
                left: 35%;
            }
        }
         
    }
    .description {
        font-size: 18px;
        font-weight: bold;
        line-height: 2;
        letter-spacing: 0.75px;
        text-align: center;
        margin: 20px auto;
    }
    .call-msg {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        font-size: 18px;
        font-weight: normal;
        line-height: 2;
        letter-spacing: 0.75px;
        text-align: center;
        margin: 0px auto 20px;
        a {
            font-weight: bold;
            cursor: pointer;
            color: map-get($colors, primary-main);
            margin-left: 10px;
            margin-right: 10px;
            &:hover {
                cursor: pointer;
                color: map-get($colors, primary-main);
            }
        }
    }
   
    @include breakpoints(medium) {
        padding: 38px 0;
    }
    .submission {
        display: flex;
        max-width: 376px;
        justify-content: center;
        margin: 20px auto;
        a {
            color: map-get($colors, white1);
            margin-right: 5px;
            position: relative;
            &:after{
                content: '';
                position: absolute;
                right: -20px;
                top: 8px;
                border: solid map-get($colors, white1);
                border-width: 0 3px 3px 0;
                padding: 4px;
                transform: rotate(-45deg);
            }
        }
        @include breakpoints('small') {
            width:80%;
        }
    }
}
@keyframes mymove {
    0%   {left: 0px;  width: 0;}
    100% {left: 0px;  width: 100%;}
}