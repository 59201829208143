.notification-wrap-admin {
  .error-input {
    color: #f91717;
    font-size: 11px;
  }
  .input-pro {
    @include border-radius(3px);
  }
  small {
    font-size: 14px;
    color: map-get($colors, rev-chk-label);
    @include breakpoints(medium) {
      font-size: 12px;
    }
  }
  .input-textarea {
    height: 100px;
    resize: none;
  }
  .noti-type {
    @include wrap-flx;
    .radio-tick {
      font-size: 14px;
      color: map-get($colors, rev-chk-label);
      margin-right: 35px;
      &:last-child {
        margin-right: 0;
      }
      @include breakpoints(small) {
        margin: 0 0 10px 0;
        width: 100%;
      }
    }
  }
  .notification-users {
    border: 2px solid map-get($colors, greyborder);
    .send-noti-table-top {
      padding: 15px 20px 0;
      @include breakpoints(small) {
        padding: 10px;
        .search-bar-sendnoti {
          margin-bottom: 10px;
        }
      }
    }
  }
  .noti-history-title {
    width: 170px;
    white-space: normal;
  }
  .noti-history-descr {
    width: 430px;
    white-space: normal;
  }
  .table-report-scroll {
    &.table-notihistory {
      .table {
        tbody {
          td {
            font-size: 14px;
          }
        }
      }
    }
  }
  @include breakpoints(small) {
    .table-sort-filter {
      width: 100%;
    }
  }
  // .table-report-scroll{
  //     &.table-noti{
  //         overflow: auto;
  //         height: 200px;
  //         .table{
  //             thead{
  //                 th{
  //                     position: sticky;
  //                     top: 0;
  //                     z-index: 2;
  //                 }
  //             }
  //         }
  //     }
  // }
}
