.provider-dash-right{
    padding-top: 25px;
    padding-bottom: 15px;
    h3{
        font-size: 28px;
        font-weight: 700;
        letter-spacing: 1px;
        margin-bottom: 25px;
        position:relative;
        @include breakpoints(small){
            font-size: 22px;
            margin-bottom: 15px;
        }
        span{
            font-size: 18px;
            font-weight: 500;
            display: block;
            letter-spacing: 0.8px;
            @include breakpoints(large){
                font-size: 16px;
                margin-top: 5px;
            }
            @include breakpoints(small){
                line-height: 22px;
            }
        }
    }
    .edit-scheduling{
        position: absolute;    
        cursor: pointer;  
        right: 0;
        top: 3px;
    }
    .scheduling-component{
        padding: 0;
        box-shadow: none;
        border:0;
    }
    .scheduling-component-main{
        padding: 0;
        .title-area{
            padding: 0;
            .title{
                padding: 0;
            }
            .cal{
                padding: 0;
            }
        }
    }
}
.pro-dash-tabs{
    .edit-tabs-btn{
        display: block;
        top: 4px;
        right: 30px;
    }
    .add-tabs-btn{
        display: block;
        top: 4px;
    }
}
.provider-dash-wrap{
    .edit-doc-profile, .edit-medical, .edit-lang, .edit-edu{
        display: block;
    }
    .profile-eligible-icons{
        display: none;
    }
}
.pro-dash-review{
    .review-filter-selection{
        display: none;
    }
    .write-review{
        // display: none;
    }
}

.modal-appointment{
    background-color: rgba($color: #000000, $alpha: 0.5);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    .modal-content-appointment{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-color: #ffffff;
        width: 430px;
        padding: 40px 0;
        @include border-radius(8px);
        @include breakpoints(small){
            padding: 20px 10px;
            width: 90%;
        }
        .modal-heading-appointment{
            h1{
                margin-bottom: 15px;
                text-align: center;
            }
        }
        .modal-text-appointment{
            font-size: 18px;
            letter-spacing: 1px;
            text-align: center;
            @include breakpoints(small){
                font-size: 14px;
            }
        }
        .upgrade-btn-appointment{
            text-align: center;
            margin-top: 20px;
            .next-btn-pro{
                display: block;
                width: 215px;
                margin: 0 auto;
                &:hover{
                    color: #ffffff;
                }
            }
        }
    }
}