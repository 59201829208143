/* Admin Header CSS start */
.admin-header {
  background-color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 2px solid #f5f6f9;
  transition: all 0.2s ease-in-out;
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 70px;
  .admin-logo {
    transition: all 0.5s ease-in-out;
    padding: 20px 20px;
    position: absolute;
    left: 0;
    opacity: 1;
    overflow: hidden;
    @include breakpoints(large) {
      left: -268px;
    }
    img {
      width: 200px;
    }
    .logo-circle {
      display: block;
      width: 40px;
    }
    .logo-normal {
      display: none;
    }
    &.activedash {
      img {
        max-width: 201px;
        width: 100%;
      }
      .logo-circle {
        display: none;
      }
      .logo-normal {
        display: block;
      }
    }
  }
  .header-topright {
    @include wrap-flx;
    justify-content: space-between;
    @include breakpoints(large) {
      justify-content: flex-start;
    }
    .header-menu-icon {
      margin-top: 22px;
    }
    .header-logo-device {
      width: 165px;
      margin: 22px 0 0 10px;
      img {
        width: 100%;
      }
    }
    .header-profile {
      margin-top: 16px;
      @include breakpoints(large) {
        margin-left: auto;
      }
      @include breakpoints(small) {
        margin-top: 8px;
      }
    }
  }
  .menu-icon {
    vertical-align: middle;
    cursor: pointer;
  }
  .admin-user {
    line-height: normal;
    cursor: pointer;
    position: relative;
    .user-icon {
      vertical-align: middle;
      @include breakpoints(small) {
        display: block;
        width: 36px;
        margin: 0 auto;
      }
    }
    .username {
      vertical-align: middle;
      position: relative;
      color: map-get($colors, primary-main);
      @include font-size($cookies-body);
      padding: 0px 15px;
      @include breakpoints(small) {
        padding: 0px 4px 0 0;
      }
    }
    .profile-arrow {
      svg {
        width: 13px;
      }
    }
  }
}
/* Admin Header CSS start */
/* Admin Left Section Navigation CSS start */
.left-admin-section {
  width: 268px;
  @media only screen and (min-width: 1140px) {
    width: 82px;
  }
  margin-left: 0;
  margin-right: auto;
  transition: all 0.5s ease-in-out;
  background-color: map-get($colors, white1);
  border-right: 2px solid #f5f6f9;
  &.activedash {
    // display: block;
    width: 268px;
    left: 0;
    .nav-link-wrapper .nav-link span {
      display: inline !important;
    }
    @include breakpoints(large) {
      left: -268px;
    }
  }
}
.right-admin-section {
  width: calc(100% - 80px);
  margin-left: auto;
  margin-right: 0;
  transition: all 0.5s ease-in-out;
  @include breakpoints(large) {
    width: 100%;
  }
  &.activedash {
    width: calc(100% - 268px);
    @include breakpoints(large) {
      width: 100%;
    }
  }
}
.left-nav-inner {
  padding: 30px 0px;
  overflow-y: auto;
  height: 100%;
  @extend %scrollbarDesign2;
  @include breakpoints(large) {
    padding: 0 0 20px;
  }
}
.closenav {
  position: absolute;
  top: 0;
  right: 10px;
  width: 25px;
  height: 25px;
  display: none;
  @include breakpoints(large) {
    display: block;
  }
  a {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;
    span {
      position: absolute;
      right: 0;
      top: 20px;
      display: block;
      width: 100%;
      &:before,
      &:after {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        height: 3px;
        background-color: map-get($colors, primary-main);
        width: 100%;
        @include transition(all 0.3s ease-in-out);
        @include transform(rotate(45deg));
      }
      &:after {
        @include transform(rotate(-45deg));
      }
    }
  }
}
.left-nav {
  height: calc(100vh - 70px);
  position: fixed;
  top: 70px;
  z-index: 998;
  left: 0;
  @include breakpoints(large) {
    left: 0;
    top: 0;
    z-index: 999;
    height: 100vh;
    padding-top: 40px;
  }
  .nav-link-wrapper {
    padding: 0px;
    margin: auto;
    list-style: none;
    li {
      padding: 5px 0px;
      position: relative;
      .dashboard-icon {
        i:before {
          background-position: 0px 0px;
        }
        &.active i:before {
          background-position: -45px 0px;
        }
      }
      .revenue-icon {
        i:before {
          background-position: -89px 0px;
        }
        &.active i:before {
          background-position: -128px 0px;
        }
      }
      .provider-icon {
        i:before {
          background-position: -170px 0px;
        }
        &.active i:before {
          background-position: -212px 0px;
        }
      }
      .patient-icon {
        i:before {
          background-position: -252px 0px;
        }
        &.active i:before {
          background-position: -288px 0px;
        }
      }
      .rating-icon {
        i:before {
          background-position: -326px 0px;
        }
        &.active i:before {
          background-position: -371px 0px;
        }
      }
      .subscription-icon {
        i:before {
          background-position: -412px 0px;
        }
        &.active i:before {
          background-position: -453px 0px;
        }
      }
      .appointments-icon {
        i:before {
          background-position: -495px 0px;
        }
        &.active i:before {
          background-position: -536px 0px;
        }
      }
      .website-icon {
        i:before {
          background-position: 0px -48px;
        }
        &.active i:before {
          background-position: -45px -48px;
        }
      }

      .admin-icon {
        i:before {
          background-position: -89px -48px;
        }
        &.active i:before {
          background-position: -130px -48px;
        }
      }
      .notification-icon {
        i:before {
          background-position: -173px -48px;
        }
        &.active i:before {
          background-position: -212px -48px;
        }
      }

      .insurance-icon {
        i:before {
          background-position: -250px -48px;
        }
        &.active i:before {
          background-position: -289px -48px;
        }
      }

      .speciality-icon {
        i:before {
          background-position: -327px -48px;
        }
        &.active i:before {
          background-position: -373px -48px;
        }
      }

      .nav-link {
        display: block;
        color: map-get($colors, grey-dark);
        @include font-size($all-filter);
        border-left: 4px solid map-get($colors, white1);
        i {
          position: relative;
          margin-right: 40px;
          &:before {
            content: "";
            position: absolute;
            background-image: url("../../admin/assets/images/admin-icons.svg");
            background-size: 578px;
            width: 45px;
            height: 45px;
            top: -12px;
            left: -7px;
          }
        }

        span {
          padding-left: 8px;
          vertical-align: bottom;
          @media only screen and (min-width: 1140px) {
            display: none;
          }
        }
        &:hover,
        &.active {
          background-image: linear-gradient(
            to right,
            rgba(7, 125, 180, 0.4) -300%,
            rgba(255, 255, 255, 0)
          );
          border-left: 4px solid map-get($colors, primary-main);
          color: map-get($colors, primary-main);
          font-weight: 500;
          .icon {
            &.g-fill {
              svg g {
                fill: map-get($colors, primary-main);
              }
            }
            &.g-stroke {
              svg g {
                stroke: map-get($colors, primary-main);
              }
            }
            &.path-stroke {
              svg path {
                stroke: map-get($colors, primary-main);
              }
            }
            &.path-fill {
              svg path {
                fill: map-get($colors, primary-main);
              }
            }
            &.text-fill {
              svg text {
                fill: map-get($colors, primary-main);
              }
            }
          }
        }
      }
    }
  }
}
.outerWrapper {
  display: flex;
  flex-wrap: wrap;
}
/* Admin Left Section Navigation CSS end */
.admin-profile-menu-wrap {
  position: absolute;
  width: 155px;
  top: 65px;
  right: -10px;
  .admin-profile-menu-inner {
    background-color: map-get($colors, white1);
    padding: 10px;
    @include box-shadow(0 3px 6px 0 #dedede);
    @include border-radius(5px);
    border: solid 1px #dfdfdf;
    font-size: 14px;
    text-align: left;
    letter-spacing: 1px;
    position: relative;
    .logout-icon {
      position: relative;
      margin: 3px 4px 0 0;
    }
    span {
      cursor: pointer;
    }
    &:before {
      position: absolute;
      content: "";
      top: -6px;
      right: 10px;
      border: solid #dfdfdf;
      border-width: 0px 1px 1px 0px;
      display: inline-block;
      padding: 5px;
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
      background-color: map-get($colors, white1);
    }
  }
}
