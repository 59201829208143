.refer-friend {
    
    .heading {
        font-size: 28px;
        font-weight: bold;
        letter-spacing: 1.17px;
        margin-bottom: 40px;
        margin-top: 40px;
        @include breakpoints(medium) {
            margin: 20px 0;
        }
        @include breakpoints(small) {
            font-size: 20px;
             margin-bottom: 0;
        }
    }
    
    .msg-box {
        display:flex;
        margin: 18px auto;
        font-size: 18px;
        letter-spacing: 0.75px;
        line-height: 1.5;
        color: map-get($colors, grey-dark);
        @include breakpoints(small) {
            font-size: 15px;
            margin-bottom: 10px;
        }
        textarea {
            @include box-shadow-style;
            width: 100%;
            height: 120px;
            padding: 18px;
            font-size: 18px;
            letter-spacing: 0.75px;
            color: map-get($colors, grey-dark);
            @include breakpoints(small) {
                font-size: 15px;
            }
        }
        .cus-radio {
            margin-right: 14px;
            label {
                &:before {
                    width: 30px;
                    height: 30px;
                    border-color: map-get($colors, primary-main);
                }
                &:after {
                    top: 10px;
                    left: 4px;
                    width: 22px;
                    height: 22px;
                }
            }
            @include breakpoints(small) {
                margin-right: 1px;
                label {
                    &:before {
                        width: 20px;
                        height: 20px;
                    }
                    &:after {
                        top: 9px;
                        left: 3px;
                        width: 14px;
                        height: 14px;
                    }
                }
            }
        }
        
        &:last-child {
            margin-bottom: 40px;
        }
    }
    .next-btn-pro {
        max-width: 220px;
        font-weight: 400;
    }
    .refer-radio{
        .cus-radio{
            position: relative;
            top: -4px;
        }
    }
}