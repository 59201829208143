.faTags {
  display: flex;
  margin-top: 10px;
  font-size: 12px;
  flex-wrap: wrap;
  .faTagsContent {
    border-radius: 5px;
    margin-bottom: 10px;
    margin-right: 10px;
    background: #2a2a2a;
    color: #ffffff;
    padding: 5px;
    display: flex;
    .removeFaCode {
      cursor: pointer;
      border-left: 1px solid #ffffff;
      padding-left: 5px;
      margin-left: 5px;
    }
  }
}
.dashboard-modal-wrap {
  .input-pro {
    font-family: $roboto;
    font-size: 16px;
    @include breakpoints(small) {
      font-size: 12px;
    }
  }
  .modal-head {
    @include modal-head;
    @include breakpoints(small) {
      padding: 10px 20px;
      margin-bottom: 15px;
    }
  }
  .sub-head {
    @include bold-font;
    font-size: 18px;
    @include breakpoints(small) {
      font-size: 15px;
    }
  }
  // edit profile
  .edit-intro {
    width: 580px;
    margin: 0 auto;
    background-color: map-get($colors, white1);
    @include breakpoints(medium) {
      width: 100%;
    }
    a {
      max-width: fit-content;
      display: block;
      margin: 0 auto 10px;
      text-align: center;
      @include medium-font;
      .edit-profile-pic {
        width: 180px;
        height: 180px;
        overflow: hidden;
        margin-bottom: 5px;
        margin-left: auto;
        margin-right: auto;
        @include border-radius(100%);
        img {
          // width: 100%;
          width: 180px;
          height: 180px;
        }
      }
      span {
        display: block;
        margin: 10px;
      }
    }
    .value-box {
      max-width: 400px;
      margin: 0 auto;
      a {
        color: map-get($colors, primary-main);
        @include medium-font;
        text-align: left;
        margin: 0;
        letter-spacing: 0;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  // Add education
  .add-edu-box,
  .edit-edu-box {
    width: 740px;
    padding: 0 20px 30px;
    @include breakpoints(medium) {
      width: auto;
    }
    @include breakpoints(small) {
      padding: 0 0px 20px;
    }
    .next-btn-pro {
      max-width: 375px;
      margin: 0 auto;
    }
    .add-btn {
      input {
        padding: 13px 0;
      }
    }
  }
  .add-edu-box {
    .desig-icon-select {
      position: relative;
      z-index: 1;
      &:before {
        left: 5px;
        top: 12px;
      }
    }
    .selecticon {
      padding-left: 45px;
    }
  }
  .border-bottom {
    padding-bottom: 20px;
    margin-bottom: 15px;
    border-width: 2px !important;
    .myform {
      position: relative;
      &.desig-icon {
        .select-service {
          background-color: transparent;
        }
      }
    }
  }
  // Edit Education
  .edit-edu-box {
    .edit-edu-head {
      font-weight: 500;
      border-bottom: 2px solid #dddddd;
      padding: 0 0 15px 0;
      margin-bottom: 15px;
      position: relative;
      .map-icon {
        padding-left: 30px;
        font-weight: 700;
      }
      span {
        padding-left: 40px;
        &:before {
          top: 0;
        }
      }
    }
  }
  .edit-buttons-wrap {
    @include wrap-flx;
    position: absolute;
    right: 0;
    top: 2px;
    z-index: 2;
    div {
      margin: 0 30px 0 0;
      @include breakpoints(small) {
        margin-right: 18px;
      }
      img {
        cursor: pointer;
        filter: grayscale(100%);
        &:hover {
          filter: grayscale(0);
        }
        &.grab-icon {
          cursor: grab;
        }
      }
      &:last-child {
        margin: 0;
      }
    }
  }
  .lang-opt-wrap {
    @include wrap-flx;
    justify-content: space-between;
    .cus-check-rev {
      width: 25%;
      font-size: 14px;
      margin-bottom: 20px;
      @include breakpoints(medium) {
        width: 32%;
      }
      @include breakpoints(small) {
        width: 46%;
      }
    }
  }

  .addres-edit-wrap {
    @include wrap-flx;
    justify-content: space-between;
    .input-pro {
      color: map-get($colors, primary-main);
    }
    .edit-loca-left,
    .edit-loca-right {
      width: 45%;
      @include breakpoints(small) {
        width: 100%;
      }
      .head-loca {
        @include medium-font;
        margin-bottom: 5px;
      }
      .loca-hours {
        @include wrap-flx;
        @include reg-font;
        span {
          &:first-child {
            width: 95px;
          }
        }
      }
      .subtxt-loca {
        @include reg-font;
      }
    }
    @include breakpoints(small) {
      .edit-loca-left {
        margin-bottom: 10px;
      }
    }
  }
  .ins-opt-wrap {
    @include wrap-flx;
    justify-content: space-between;
    .cus-check-rev1 {
      border: 1px solid map-get($colors, primary-main);
      padding: 10px 20px 5px 5px;
    }
    .cus-check-rev {
      font-size: 14px;
      margin-bottom: 20px;
      width: 33%;
      @include breakpoints(medium) {
        width: 48%;
      }
      @include breakpoints(small) {
        width: 100%;
        margin-bottom: 15px;
      }
    }
  }
  .add-cus-ins-box {
    @include wrap-flx;
    div {
      &:first-child {
        width: 100%;
      }
      &:nth-child(2) {
        width: 320px;
        margin-right: 10px;
      }
      &:last-child {
        .next-btn-pro {
          width: 95px;
          padding: 16px 0;
          @include border-radius(0);
          font-weight: 400;
          font-size: 15px;
        }
      }
      @include breakpoints(small) {
        &:nth-child(2) {
          width: 210px;
          margin-right: 10px;
        }
        &:last-child {
          .next-btn-pro {
            width: 60px;
            padding: 13px 0;
          }
        }
      }
    }
  }
  .focus-modal-wrap {
    datalist {
      // width: 500px;
      // max-width: 500px;
      // option{
      //     width: 500px;
      //     max-width: 500px;
      //     display: block;
      // }
    }
    // input[list] {
    //     width: 450px;
    // }
    .focus-area-list,
    .focus-area-list-add {
      li {
        @include medium-font;
        position: relative;
        padding-left: 30px;
        border-bottom: 1px solid #ececec;
        padding-bottom: 13px;
        margin-bottom: 15px;
        @include breakpoints(small) {
          padding-right: 65px;
        }
        &:before {
          content: "+";
          position: absolute;
          width: 26px;
          height: 26px;
          left: 0;
          top: -20px;
          font-size: 40px;
          font-weight: 700;
          font-family: arial;
          color: rgba($color: #000000, $alpha: 0.65);
          @include breakpoints(small) {
            font-size: 38px;
          }
        }
      }
    }
    @include breakpoints(small) {
      .focus-area-list-add {
        li {
          padding-right: 0;
        }
      }
    }

    .add-subtext {
      font-size: 14px;
      font-family: $lato;
      margin-top: 4px;
    }
    .suggest-focus-head {
      font-weight: 700;
      font-size: 18px;
      margin: 30px 0 24px;
      letter-spacing: 0.5px;
      @include breakpoints(small) {
        font-size: 16px;
        margin-top: 15px;
      }
    }
  }
}
.croppper {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  height: 200px;
  background: #333;
}
