.rating-filter-box{
    @include wrap-flx;
    margin: 25px 0 15px;
    @include breakpoints(small){
        justify-content: space-between;
    } 
    .table-sort-filter{
        width: 100%;
        margin: 0;
        .filter-input{
            max-width: 100%;
        }
        .sort-select{
            width: 100%;
            max-width: 100%;
        }
    }    
    .rating-search{
        width: 37.5%;   
        margin-right: 15px; 
        @include breakpoints(medium){
            width: 45.7%;
        } 
        @include breakpoints(small){
            width: 100%;
            margin: 0 0 10px 0;
        }  
    }
    .rating-filter{
        width: 26%;
        margin-right: 15px;
        @include breakpoints(medium){
            width: 28%;
        }
        @include breakpoints(small){
            width: 100%;
            margin: 0 0 10px 0;
        } 
    }
    .rating-sort{
        width: 15%;
        @include breakpoints(medium){
            width: 22%;
        }
        @include breakpoints(small){
            width: 60%;
            margin: 0;
        } 
    }
    .rating-export{
        width: 12%;
        margin-left: auto;
        @include breakpoints(medium){
            width: 100%;
            text-align: right;
            margin-top: 10px;
        }
        @include breakpoints(small){
            width: 35%;
            margin:0;
        } 
        .link-btn{
            text-align: center;
            width: 110px;
            float: right;
            @include breakpoints(small){
                width: 100%
            } 
        }
    }
}

.rating-detail-wrap{
    width: 510px;
    @include breakpoints(large){
        width: auto;
    }
    .rating-detail-head{
        @extend %rating-detail-head-font;
        margin-bottom: 25px;
    }
    .rating-comments-box{
        @include wrap-flx;
        @extend %rating-detail-text-font;
        margin-bottom: 20px;
        justify-content: space-between;
        .rating-comment-left{
            width: 27%;
            font-weight: 500;
        }
        .rating-comment-right{
            width: 63%;
        }
    }
}

.rating-review-wrap{
    margin: 30px 0 0 0;
    .ratrev-top{
        @include wrap-flx;
        margin-bottom: 40px;
        .ratrev-left, .ratrev-right{
            span{
                display: block;
                &:first-child{
                    @extend %rating-detail-text-font;
                    font-weight: 500;
                    margin-bottom: 5px;
                }
                &:last-child{
                    font-size: 14px;
                }
            }
        }
        .ratrev-left{
            width: 210px;
        }
    }
    .ratrev-rating{
        margin-bottom: 30px;
        @extend %rating-detail-text-font;
        font-weight: 500;
        .ratrev-rating-head{
            display: block;
            margin-bottom: 5px;
        }
        .comment-area-rating{
            .input-pro{
                @include border-radius(4px);
                border-color: map-get($colors, ford-gray);
                max-width: 80%;
                min-height: 125px;
                resize: none;
                @include breakpoints(large){
                    max-width: 100%;
                }
            }
        }
    }
}

.comment-rating{
    width: 300px;
    white-space: normal;
}
.sort-doubleline{
    .sort-outer{
        display: block;
        .data-sort-icon{
            right: -5px;
        }
    }
}
.rating-export-wrap{
    width: 490px;
    margin: 0 auto;
    @include breakpoints(small){
        width: auto;
    }
    .rating-export-inner{
        margin: 0 25px;
        margin-bottom: 25px;
        @include breakpoints(small){
            margin: 0 0 20px;
        }
    }
    .export-period{
        @include wrap-flx;
        justify-content: space-between;
        .exp-period-left, .exp-period-right{
            width: 47%;
            position: relative;
            @include breakpoints(small){
                width: 100%;
            }
        }
        .exp-period-left{
            @include breakpoints(small){
                margin: 0 0 20px;
            }
        }
    }
    .all-fltrbtn{
        margin: 10px 0 0;
        padding-bottom: 15px;
        @include breakpoints(small){
            padding-bottom: 0px;
        }
        .next-btn-pro, .rev-btn-pro{
            width: 130px;
            height: 52px;
            margin: 0;
            padding: 0;
            font-size: 16px;
            font-weight: 400;
            @include breakpoints(small){
                height: 45px;
                width: 110px;
            }
        }
        .rev-btn-pro{
            margin-right: 20px;
            @include breakpoints(small){
                margin-right: 10px;
            }
        }
    }
}
