.tabComponent {
  & h4 {
    display: inline-flex;
    width: 100%;
    position: relative;
    a {
      color: inherit;
      text-decoration: none;
      margin-left: auto;
      margin-right: auto;
      z-index: 2;
      background: map-get($colors, white1);
      padding-left: 10px;
      padding-right: 10px;
    }
    &:before {
      content: "";
      border: 1px solid #ececec;
      width: 100%;
      display: block;
      position: absolute;
      top: 50%;
    }
  }
  & .description {
    margin-top: 16px;
    display: block;
    text-align: center;
    color: map-get($colors, grey-dark);
    @include breakpoints(small) {
      font-size: 14px;
    }
  }

  & .nav-tabs {
    margin-top: 55px;
    display: flex;
    justify-content: space-between;
    @include breakpoints(small) {
      .nav-link {
        padding: 0.3rem 0.3rem;
      }
    }
    & .nav-item.nav-link {
      flex-grow: 1;
      color: map-get($colors, black_color);
      text-align: center;
      border-bottom: 2px solid map-get($colors, primary-main);
      &:hover {
        outline: none;
        border: none;
        border-bottom: 2px solid map-get($colors, primary-main);
      }
      // background-color: map-get( $colors, grey-lighter);
      position: relative;
      & .topmsg {
        position: absolute;
        top: -22%;
        left: 50%;
        padding: 0;
        transform: translateX(-50%);
        color: map-get($colors, primary-main);
        width: 70%;
        box-shadow: none;
        font-weight: 500;
        @include breakpoints(small) {
          font-size: 11px;
          top: -30px;
        }
      }
      div {
        background-color: map-get($colors, white1);
        border-radius: 8px;
        box-shadow: 0 4px 14px 0 map-get($colors, light-grayish-blue);
        padding: 20px 10px;
        @include breakpoints(small) {
          padding: 5px 0;
        }
        h3 {
          font-weight: 700;
          @include breakpoints(small) {
            font-size: 16px;
          }
        }
        @include breakpoints(small) {
          p {
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 5px;
            .block-mobile {
              display: block;
              width: 100%;
            }
          }
        }
      }
      &:first-of-type {
        padding-left: 0px;
        border-left: 0;
      }
      &:last-of-type {
        padding-right: 0px;
        border-right: 0;
      }
      &.active {
        border: 2px solid map-get($colors, primary-main);
        background-color: map-get($colors, white1);
        border-bottom: 0;
        padding: 0;
        div {
          color: map-get($colors, primary-main);
          box-shadow: unset;
          border-radius: 8px;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          border-bottom: none;
        }
        & .topmsg {
          color: map-get($colors, green-shade);
        }
      }
    }
  }
  & .tab-content {
    border: 2px solid map-get($colors, primary-main);
    border-radius: 8px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: 0;
    padding-top: 30px;
    overflow: hidden;

    padding-bottom: 0px;
    .tab-pane {
      .container {
        padding: 0px 30px;
        @include breakpoints(small) {
          padding: 0 10px;
        }
        .row {
          margin-bottom: 10px;
          &:last-child {
            border-bottom: 2px solid map-get($colors, white-smoke);
            padding-bottom: 19px;
            margin-bottom: 0px;
          }
          div {
            padding: 0px;
            @include breakpoints(small) {
              font-size: 14px;
            }
            .red-color {
              color: red;
            }
          }
          &.totalTrail {
            margin-top: 19px;
          }
          &.total {
            margin-top: 19px;
            border-bottom: unset;
            font-weight: 700;
          }
        }
      }
    }

    .des {
      padding: 28px 14px;
      background-color: map-get($colors, white-smoke);
      color: map-get($colors, grey-dark);
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      @include breakpoints(small) {
        font-size: 12px;
        padding: 10px 0;
      }
    }
  }
}
.cardFormContainer {
  .seaprator-title {
    margin-top: 32px;
  }
  .cardForm {
    margin-top: 45px;
    max-width: 740px !important;
  }
  .radio-payment {
    @include wrap-flx;
    .pay-pal-icon {
      position: relative;
      top: 7px;
    }
    .cus-radio {
      label {
        &:before {
          width: 30px;
          height: 30px;
          margin-right: 15px;
        }
      }
      &:first-child {
        margin-right: 70px;
        @include breakpoints(small) {
          margin: 0 0 15px 0;
        }
      }
      > input:checked + label:after {
        width: 20px;
        height: 20px;
        top: 11px;
        left: 5px;
      }
      > input:checked + label:before {
        border-color: map-get($colors, primary-main);
      }
    }
  }
}
