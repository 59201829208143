.doctor-profile-wrap{
    .provider-profile-heading{
        .back-btn{
            span{
                display: block;
            }
        }
    }
    .doc-save{
        top: 30px;
        left: 35px;
        display: block;
    }
    .doc-profile-switcher-wrap{
        display: none;
    }
    .book-appointment-wrap, .calender-wrap{
        display: block;
    }
    .patient-features, .endorse-link{
        display: none;
    }
    .endorse-link{
        margin-top: 60px;
        @include breakpoints(small){
            margin-top: 30px;
            padding-top: 30px;
        }
    }
    .endorse-link-patient{
        display: block;
    }
}

// doctor review modal
.doctor-review-modal-wrap{
    width: 770px;
    padding: 50px 40px 10px;
    @include breakpoints(medium){
        width: 100%;
    }
    @include breakpoints(small){
        padding: 60px 0px 0;
    }
    .docreview-inner{
        max-width: 640px;
        margin: 0 auto;
        .docreview-toptext, .docreview-botext{
            font-size: 21px;
            color: map-get($colors, black1);
            text-align: center;
            font-weight: 700;
            letter-spacing: 0.8px;
            line-height: 1.7;
            @include breakpoints(small){
                font-size: 18px;
                line-height: 1.5;
            }
            span{                
                display: block;
            }
            .redtext{
                color: map-get($colors, light-red);
            }
        }
        .docreview-botext{
            color: map-get($colors, primary-main);
            span{                
                display: inline;
            }
        }
        .doctor-reviewimg{
            width: 150px;
            height: 150px;
            overflow: hidden;
            margin: 25px auto;
            @include border-radius(100%);
            img{
                width: 100%;
            }
        }
        .rating-doctor-review{
            text-align: center;
            margin: 15px auto 40px;
            @include breakpoints(small){
                margin: 15px auto;
            }
        }
        .ratingreview-textarea{
            .input-pro{
                border-color: map-get($colors, ford-gray);
                @include border-radius(3px);
                height: 120px;
            }
            .limit{
                font-size: 14px;
                font-weight: 400;
                color: #a7a9ac;
                letter-spacing: 0.6px;
            }
        }
        .post-btn{
            text-align: center;
            .next-btn-pro{
                max-width: 375px;
                margin: 25px auto 15px;
                font-size: 16px;
            }
        }
    }
}