.provider-profile-heading {
  @include wrap-flx;
  margin: 25px 0 40px;
  align-items: center;
  @include breakpoints(small) {
    margin: 20px 0 30px;
  }
  .back-btn {
    width: 100%;
    display: block;
    font-size: 20px;
    margin-top: 15px;
    @include wrap-flx;
    justify-content: space-between;
    @include breakpoints(medium) {
      font-size: 14px;
    }
    @include breakpoints(small) {
      margin-top: 5px;
    }
    a {
      color: map-get($colors, grey-dark);
      font-weight: 500;
      .bk-arrow {
        position: relative;
        top: 3px;
        margin-right: 10px;
        @include breakpoints(medium) {
          margin-right: 4px;
          width: 15px;
        }
      }
    }
    span {
      font-size: 16px;
      color: map-get($colors, black1);
      letter-spacing: 0.6px;
      position: relative;
      padding-left: 35px;
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: -2px;
        width: 24px;
        height: 24px;
        background-color: map-get($colors, delete-color);
        @include border-radius(100%);
      }
      @include breakpoints(small) {
        font-size: 14px;
        width: 100%;
        margin-top: 10px;
      }
      a {
        color: map-get($colors, primary-main);
        font-weight: 400;
        text-decoration: underline;
      }
    }
  }
}
