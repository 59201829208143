.sub-admin-wrap{
    margin-top: 30px;
    @include breakpoints(small){
        margin-top: 20px;
    }
    .subadmin-top{
        border-bottom: 2px solid map-get($colors, write-review);
        padding-bottom: 25px;
        margin-bottom: 25px;
        @include wrap-flx;
        .subadmin-left{
            margin: 0 45px 0 0;
            width: 166px;
            @include breakpoints(large){
                margin-right: 30px;
            }
            @include breakpoints(small){
                margin: 0 auto 20px;
            }
            .subadmin-profileimg{
                width: 100%;
                height: 166px;
                @include border-radius(4px);
                border: 1px solid map-get($colors, ford-gray);
                overflow: hidden;
                margin-bottom: 8px;
            }
            .button-anchor{
                .white-btn{
                    @include border-radius(4px);
                }
            }
        }
        .subadmin-right{
            width: 670px;           
            @include breakpoints(large){
                width: 68%;
            }
            @include breakpoints(small){
                width: 100%;
            }
            .subadmin-right-inner{
                @include wrap-flx;
                justify-content: space-between;
                .right-fields{
                    width: 48%;
                    margin-bottom: 27px;
                    @include breakpoints(small){
                        width: 100%;
                        margin-bottom: 15px;
                        &:last-child{
                            margin-bottom: 0;
                        }
                    }
                    .table-sort-filter{
                        position: relative;
                        margin: 0;
                        width: 100%;
                        .sort-select{
                            max-width: 100%;
                        }
                    }
                    .rw-multiselect-input{
                        padding: 0px 14px !important;
                    }
                }
            }
            .input-pro{
                @include border-radius(4px);
                border-color:map-get($colors, ford-gray);
                height: auto;
                min-height: 50px;
                .rw-widget-input{
                    height: auto;
                    margin: 7px 4px;
                    border: none;
                    box-shadow: none;
                    box-sizing: border-box;
                    width: 98%;
                    input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                        color: #7a7a7a;
                      }
                }
            }
        }
    }
    .bottom-btns{
        margin-bottom: 40px;
        .next-prev-btn-pro{
            .prev-btn-pro, .next-btn-pro{
                font-weight: 400;
                width: 180px;
                @include breakpoints(small){
                    width: 150px;
                }
            }
        }
    }
}

.sub-admin-modal{
    .profile-img{
        max-width: 200px;
        max-height: 200px;
        border-radius: 5px;
    }
}