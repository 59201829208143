.search-filter{
    .search-filter-box{
        background-color: map-get($colors, white1);
        width: 800px;
        margin: 0 auto;
        padding-bottom: 30px;
        @include breakpoints(medium){
            width: 100%;
        }
        .modal-fields{
            max-width: 90%;
            margin: 0 auto;
        }
        .modal-subhead{
            @include modal-head;
            border: 0;
            text-align: left;
            margin-bottom: 10px;
            padding: 0;
        }
        .modal-border{
            border-bottom: 1px solid map-get($colors, modal-border);
            padding-bottom: 25px;
            margin-bottom: 25px;
            .medical-select{
                margin-top: 10px;
            }
        }
        .speciality-filter{
            @include wrap-flx;
            align-items: flex-start;
            font-size: 14px;
            .cus-check-rev{
                width: 33%;
                margin-bottom: 23px;
                @include breakpoints(small){
                    width: 100%;
                }
            }
        }
        .next-btn-pro{
            width:220px;
            margin: 0 auto;
        }
    }
    h4{
        @include modal-head;
    }
}