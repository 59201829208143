.seaprator-title,
%seaprator-title {
  position: relative;
  text-align: center;
  font-size: 20px;
  color: #000;
  font-weight: 500;
  margin: 0 auto;
  width: 100%;
  span {
    background-color: map-get($colors, white1);
    position: relative;
    z-index: 1;
    padding: 0 7px;
  }
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 49%;
    height: 1px;
    width: 100%;
    background-color: map-get($colors, border-grey);
  }
}
.upgrade-modal-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1049;
  text-align: center;
}
.modal-wrapper {
  background-color: rgba(0, 0, 0, 0.5);
  padding-top: 30px;
}

// Star rating
.stars-outer {
  max-width: 145px;
  margin: 0 auto;
  @include wrap-flx;
  flex-wrap: nowrap;  
  justify-content: space-between;
  .star {
    font-size: 20px;
    width: 27px;
    position: relative;
    line-height: 24px;
    cursor: pointer;
    margin-right: 2px;
    transition: all 0.2s ease-in;
    &:before {
      content: "\2605";
      color: white;
      background-color: #d6d6d6;
      border-radius: 3px;
      padding: 0;
      transition: all 0.2s ease-in;
      width: 100%;
      height: 100%;
      position: absolute;
      text-align: center;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
    }
    &:hover {
      &:before {
        // background-color: #ffcc00;
      }
    }
    &.full {
      &:after {
        content: "\2605";
        color: white;
        position: absolute;
        width: 100%;
        overflow: hidden;
        background-color: #ffcc00;
        border-radius: 3px;
        text-align: center;
        padding: 0;
        left: 0;
        top: 0px;
        bottom: 0;
        right: 0;
      }
    }
    &.half {
      &:after {
        content: "\2605";
        color: white;
        position: absolute;
        width: 13px;
        overflow: hidden;
        background-color: #ffcc00;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        left: 1px;
        padding: 1px 0px 1px 4px;
        top: -1px;
      }
    }
  }
}

.slidecontainer {
  display: flex;
  justify-content: "center";
}

.percentGraph {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  font-size: 14px;
}

