.provider-step4-wrap{
    .provider-step4-inner{
        max-width: 1150px;
        border: 1px solid #cdcdcd;
        background-color:map-get($colors, white1);
        border-radius: 10px;
        box-shadow: 0 6px 9px 0 rgba(228, 238, 243, 0.64);
        padding: 27px 0 21px;
        margin-bottom: 90px;
        @include breakpoints(medium){
            border: none;
            box-shadow:none;
            margin-bottom: 0;
        }
        .plan-outer{
            padding: 4px;
            @include wrap-flx;
            .plan-left{
                width: 284px;
                @include breakpoints(large){
                    width: 270px;
                }
                @include breakpoints(medium){
                    width: 100%;
                }
                .plan-left-top{
                    h2{
                        text-align: center;
                        font-weight: 700;
                        margin-bottom: 115px;
                        margin-top: 25px;   
                        @include breakpoints(medium){
                            margin-bottom: 20px;
                            margin-top: 0;
                        }                    
                    }
                    .pro-service{
                        text-align: center;
                        font-size: 20px;
                        letter-spacing: 1.2px;
                        color:map-get($colors, black1);
                        background-color: map-get($colors, grey-plan);
                        font-weight: 700;
                        padding: 13px 0;
                        @include breakpoints(medium){
                            display: none;
                        }
                    }
                } 
                .plan-left-bottom{
                    border-top:2px solid map-get($colors, grey-plan);
                    padding: 15px 5px 15px 15px;
                    height:56px;
                    @include breakpoints(large){
                        font-size: 14px;
                    }
                    @include breakpoints(medium){
                        display: none;
                    }
                    .info-icon{
                        width: 24px;
                        height: 24px;
                        margin: 0 8px 0 0;
                        color:map-get($colors, black1);
                    }
                }                               
            }
            .plan-right{
                width: 850px;
                @include breakpoints(large){
                    width: 690px;
                }
                @include breakpoints(medium){
                    width: 100%;
                }
                .plan-right-inner{
                    @include wrap-flx;
                    justify-content: space-between;
                    margin-right: 15px;
                    @include breakpoints(large){
                        margin-right: 0;
                    }
                    >div{
                        border: 1px solid #979797;
                        @include border-radius($radius: 8px);
                        width:200px;
                        // overflow: hidden;
                        position: relative;
                        @include breakpoints(large){
                            width: 165px;
                        }
                        @include breakpoints(medium){
                            width: 48%;
                            margin-bottom: 50px;
                        }
                        @include breakpoints(small){
                            width: 100%;
                            margin-bottom: 45px;
                        }
                        a{
                            text-decoration: none;
                            display: block;  
                            @include border-radius(8px);                       
                            .plan-right-top{
                                h2{
                                    text-align: center;
                                    font-weight: 700;
                                    padding-top: 25px;
                                    color: map-get($colors, black1);
                                    span{
                                        font-size: 20px;
                                        color: map-get($colors, black1);
                                        font-weight: 400;
                                        display: block;
                                        margin: 18px 0 20px;
                                    }
                                }
                                .try-box, .try-box1{
                                    width: 165px;
                                    margin: 0 auto;
                                    text-align: center;
                                    letter-spacing: 1px;
                                    border: 2px solid map-get($colors, primary-main);
                                    @include border-radius($radius: 6px);
                                    color: map-get($colors, primary-main);
                                    font-weight: 500;
                                    padding: 11px 0;
                                    @include breakpoints(large){
                                        font-size: 14px;
                                        width: 90%;
                                    }
                                    @include breakpoints(medium){
                                        width: 160px;
                                        height: 52px;
                                        padding: 13px;
                                    }
                                    &.active {
                                       color: map-get($colors, white1); 
                                       background-color: map-get($colors, primary-main);
                                    }
                                }
                                .try-box1{
                                    padding: 6px 20px;
                                    line-height: 17px;
                                    @include breakpoints(medium){
                                        padding: 6px 30px;
                                    }
                                }
                                .purchase-now{
                                    font-weight:500;
                                    text-align: center;
                                    letter-spacing: 1px;
                                    margin: 18px 0 20px 0;
                                    span{
                                        color: map-get($colors, primary-main);
                                    }
                                    @include breakpoints(medium){
                                        margin-bottom: 10px;
                                    }
                                }
                            }
                            .plan-right-bottom{
                                border-top:2px solid map-get($colors, grey-plan);
                                padding: 15px 5px 15px 15px;
                                text-align:center;
                                height: 56px;
                                @include wrap-flx;
                                justify-content: space-between;
                                align-items: center;
                                position: relative;
                                &:after{
                                    content: '';
                                    position: absolute;
                                    height: 100%;
                                    width: 2px;
                                    background-color: map-get($colors, grey-plan);
                                    right: 20%;
                                    display: none;
                                    @include breakpoints(medium){
                                        display: block;
                                    }
                                    @include breakpoints(small){
                                        right: 28%;
                                    }
                                }
                                .left-text-device{
                                    font-size: 14px;
                                    line-height: 1.3;
                                    color: map-get($colors, black1);
                                    width: 80%;
                                    text-align: left;
                                    position: relative; 
                                    display: none;
                                    @include breakpoints(medium){
                                        display: block;
                                    }
                                    .info-icon{
                                        width: 16px;
                                        margin: 0 7px 0 0;
                                        position: relative;                                        
                                    }
                                    @include breakpoints(small) {
                                       width: 70%; 
                                    }
                                }
                                .right-icon{
                                    width:100%;
                                    position: relative;
                                    @include breakpoints(medium){
                                        width: 20%;
                                    }
                                    img{
                                        width: 30px;
                                    }                                    
                                }
                            }
                            &:hover{
                                background-color: #f2fbff;
                                .try-box, .try-box1{
                                    background-color: map-get($colors, primary-main);
                                    color:map-get($colors, white1);
                                    @include transition(0.2s all ease-in-out);
                                }
                            }
                            &.active{
                                background-color: #f2fbff;
                                .try-box, .try-box1{
                                    background-color: map-get($colors, primary-main);
                                    color:map-get($colors, white1);
                                }
                                &.upgrade {
                                    .try-box, .try-box1{
                                        background-color: map-get($colors, white1);
                                        color:map-get($colors,primary-main);
                                    }
                                }
                            }
                        }
                        @include breakpoints(medium){
                            &:first-child{
                                .purchase-now{
                                    opacity: 0;
                                }
                            }
                        }
                        .pro-service-right{
                            text-align: center;
                            font-size: 16px;
                            letter-spacing: 1.2px;
                            color: map-get($colors, black1);
                            background-color: map-get($colors, grey-plan);
                            font-weight: 700;
                            padding: 15px 0;
                            &.pro-service-get-start{
                                margin-top: 52px;
                                @include breakpoints(small){
                                    margin-top: 20px;
                                }
                            }
                        }
                    }
                }                
            }
        }
    }
    .cancel-link{        
        display: none;
    }
}