.admin-login {
    .admin-login-row {
        height: calc(100vh - 10px);
        position: relative;
        .admin-login-wrapper {
            max-width: 560px;
            margin: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            .admin-logo {
                max-width: 358px;
                width: 100%;
                margin: auto;
                margin-bottom: 68px;
                @include breakpoints(small) {
                    margin-bottom: 30px;
                    max-width: 250px;
                }
                img {
                    width: 100%;
                }
            }
            h1.login-head {
                text-align: center;
                color: #474747;
                font-size: 30px;
                line-height: normal;
                margin-bottom: 15px;
                letter-spacing: 1px;
                @include breakpoints(small) {
                    font-size: 22px;
                }
            }
            .admin-login-form {
                .pro-label {
                    font-size: 18px;
                    @include breakpoints(small) {
                        font-size: 14px;
                    }
                }
                .input-pro {
                    letter-spacing: 1px;
                    color: #929d9d;
                    padding: 12px 20px;
                    font-size: 16px;
                    border-radius: 4px;
                    @include breakpoints(small) {
                        font-size: 13px;
                    }
                }
                .submit-btn {
                    .next-btn-pro {
                        height: 54px;
                        line-height: 45px;
                        padding: 0px 5px;
                        letter-spacing: 1px;
                        text-align: center;
                        font-size: 20px;
                        font-weight: 500;
                        margin-top: 18px;
                        @include breakpoints(small) {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}