.home-wrap {
  padding: 0;
  .home-top-container {
    background-color: map-get($colors, home-top);
    position: relative;
    @include box-shadow(inset 0px 2px 12px -3px rgba(0, 0, 0, 0.05));
    padding: 60px 0 30px;
    @include breakpoints(medium) {
      padding: 20px 0 20px;
    }
    .home-logo {
      text-align: center;
      max-width: 700px;
      margin: 0 auto;
      @include breakpoints(medium) {
        width: 100%;
        img {
          width: 75%;
        }
      }
      span {
        font-size: 28px;
        font-weight: 500;
        color: map-get($colors, black1);
        text-align: center;
        display: block;
        margin: 20px 0 0;
        letter-spacing: 0.6px;
        @include breakpoints(medium) {
          font-size: 18px;
        }
        @include breakpoints(small) {
          font-size: 14px;
        }
      }
    }
    .homelogo-new {
      @include wrap-flx;
      justify-content: center;
      .logo-icon {
        width: 70px;
        @include breakpoints(small) {
          width: 34px;
        }
      }
      strong {
        color: map-get($colors, primary-main);
        margin-left: 5px;
        font-size: 72px;
        line-height: 70px;
        @include breakpoints(small) {
          margin-left: 3px;
          font-size: 34px;
          line-height: 33px;
        }
      }
    }
  }
  .home-top-fields {
    margin: 50px auto 0;
    padding-bottom: 15px;
    @include wrap-flx;
    justify-content: space-between;
    @include breakpoints(small) {
      margin-top: 20px;
    }
    span {
      position: relative;
      &:nth-child(1) {
        width: 37%;
        @include breakpoints(large) {
          width: 100%;
          margin-bottom: 20px;
        }
        @include breakpoints(small) {
          margin-bottom: 10px;
        }
        input {
          padding-left: 60px;
          @include breakpoints(small) {
            padding-left: 45px;
          }
        }
      }
      &:nth-child(2) {
        width: 21%;
        @include breakpoints(large) {
          width: 35%;
        }
        @include breakpoints(medium) {
          width: 33%;
        }
        @include breakpoints(small) {
          width: 100%;
          margin-bottom: 15px;
        }
        input {
          padding-left: 65px;
          @include breakpoints(small) {
            padding-left: 50px;
          }
        }
      }
      &:nth-child(3) {
        width: 25.5%;
        @include breakpoints(large) {
          width: 48%;
        }
        @include breakpoints(medium) {
          width: 41%;
        }
        @include breakpoints(small) {
          width: 100%;
        }
      }
      &:nth-child(4) {
        width: 12%;
        @include breakpoints(medium) {
          width: 23%;
        }
        @include breakpoints(small) {
          width: 100%;
          margin-top: 10px;
        }
      }
    }
    &.searchFilter {
      span {
        position: relative;
        &:nth-child(1) {
          width: 42%;
          @include breakpoints(large) {
            width: 100%;
            margin-bottom: 20px;
          }
          @include breakpoints(small) {
            margin-bottom: 10px;
          }
          input {
            padding-left: 60px;
            @include breakpoints(small) {
              padding-left: 45px;
            }
          }
        }
        &:nth-child(2) {
          width: 23%;
          @include breakpoints(large) {
            width: 35%;
          }
          @include breakpoints(medium) {
            width: 33%;
          }
          @include breakpoints(small) {
            width: 48%;
          }
          input {
            padding-left: 65px;
            @include breakpoints(small) {
              padding-left: 50px;
            }
          }
        }
        &:nth-child(3) {
          width: 29%;
          @include breakpoints(large) {
            width: 48%;
          }
          @include breakpoints(medium) {
            width: 41%;
          }
          @include breakpoints(small) {
            width: 50%;
          }
        }
        &:nth-child(4) {
          width: 13%;
          @include breakpoints(medium) {
            width: 23%;
          }
          @include breakpoints(small) {
            width: 100%;
            margin-top: 10px;
          }
        }
      }
    }
    .find-home {
      @include find-home;
    }
    .input-home {
      @include input-home;
    }
    .search-home-btn {
      @include search-home;
      height: 63px;
    }
  }
  .home-bottom-field-txt {
    span {
      color: map-get($colors, black1);
      font-size: 13px;
      display: block;
      font-weight: 400;
      letter-spacing: 0.6px;
      &.f-wdth {
        width: 100%;
        @include breakpoints(small) {
          font-size: 11px;
          letter-spacing: 0px;
        }
      }
    }

    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .home-check {
    padding: 20px 0 0;
    margin: 0;
    @include breakpoints(small) {
      .cus-check {
        label {
          font-size: 12px;
        }
      }
    }
  }
  .specialty-doc-list-wrap {
    padding: 50px 0 0;
    h1 {
      @include breakpoints(small) {
        font-size: 20px;
        line-height: 1.35;
        letter-spacing: 0.48px;
        text-align: center;
        font-weight: 500;
      }
    }
    @include breakpoints(medium) {
      padding-top: 30px;
    }
    .spec-list-cat {
      margin: 40px auto 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      @include breakpoints(medium) {
        margin-top: 20px;
        justify-content: space-between;
      }
      @include breakpoints(small) {
        margin-bottom: 20px;
      }
      a {
        display: block;
        width: 23%;
        @include breakpoints(medium) {
          width: 48%;
        }
        @include breakpoints(small) {
          width: 48%;
        }
      }
      .img-container {
        position: relative;
        width: 100%;
        margin-bottom: 75px;
        @include box-shadow(0 6px 18px 0 rgba(0, 0, 0, 0.1));
        @include breakpoints(medium) {
          margin-bottom: 60px;
        }
        @include breakpoints(small) {
          margin-bottom: 55px;
        }
        .cat-img {
          width: 100%;
          height: auto;
          display: block;
          position: relative;
          z-index: 2;
        }
        &:hover {
          .overlay-cat {
            opacity: 0;
          }
          .cat-overlay-text {
            color: map-get($colors, black1);
            bottom: -11%;
            @include transition(all 0.5s ease-in-out);
            @include breakpoints(medium) {
              bottom: -22px;
            }            
          }
          .cat-border-box-bottom {
            bottom: -24%;
            @include breakpoints(medium) {
              bottom: -50px;
            }
          }
        }
        @include breakpoints(small) {
          .overlay-cat {
            opacity: 0;
          }
          .cat-overlay-text {
            color: map-get($colors, black1);
            bottom: -11%;
            @include transition(all 0.5s ease-in-out);
            @include breakpoints(small) {
              font-size: 14px;
              letter-spacing: 0.5;
            }
            @include breakpoints(medium) {
              bottom: -22px;
            }
            @include breakpoints(small) {
              bottom: -18px;
            }
          }
          .cat-border-box-bottom {
            bottom: -24%;
            @include breakpoints(medium) {
              bottom: -50px;
            }
            @include breakpoints(small) {
              bottom: -36px;
            }
          }
        }
      }
      .overlay-cat {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        z-index: 9;
        opacity: 0.5;
        @include transition(all 0.5s ease-in-out);
        background-color: #214760;
      }
      .cat-overlay-text {
        color: white;
        font-size: 22px;
        font-weight: 700; 
        z-index: 10;
        letter-spacing: 1px;
        position: absolute;
        bottom: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, 50%);
        -ms-transform: translate(-50%, 50%);
        transform: translate(-50%, 50%);
        text-align: center;
        width: 100%;
        @include transition(all 0.5s ease-in-out);
      }
      .cat-border-box-bottom {
        width: 100%;
        z-index: 0;
        position: absolute;
        bottom: 0;
        border: 1px solid #d3d3d3;
        height: 50px;
        border-top: 0;
        background: map-get($colors, white1);
        z-index: 1;
        @include box-shadow(0 6px 18px 0 rgba(0, 0, 0, 0.1));
        @include transition(all 0.5s ease-in-out);
      }
    }
  }
}
.cateFindItem {
  padding: "0.25rem 1.25rem";
  font-size: 14px;
  border: 0px;
  border: transparent solid 1px;
}
.cateFindItem:hover {
  color: #077db4;
  border: #077db4 solid 1px;
  background: #fff;
  cursor: pointer;
}
.autoContainerCate {
  background-color: #fff;
  box-shadow: 0 2px 7px 0 #cedae1;
  overflow-y: scroll;
  max-height: 250px;
  position: absolute;
  top: 60px;
  width: 100%;
  z-index: 999999;
  @extend %scrollbarDesign3;
}
.cateSearch {
  padding: 10px 5px;
  font-size: 15px;
}
.autoContenrCate {
  background-color: #fff;
  margin-bottom: 5px;
}
.autoContainerInsuranceName {
  background-color: #fff;
  box-shadow: 0 2px 7px 0 #cedae1;
  overflow-y: scroll;
  max-height: 250px;
  position: absolute;
  top: 50px;
  width: 100%;
  z-index: 999999;
  @extend %scrollbarDesign3;
}
.autoContentInsuranceName {
  background-color: #fff;
  margin-bottom: 5px;
}
.insuranceItem:hover {
  color: #077db4;
  border: #077db4 solid 1px;
  background: #fff;
  cursor: pointer;
}
