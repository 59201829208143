.profile-tabs{
    @include box-shadow-style;
    padding: 10px 50px 0;
    &.border-0 {
        border: 0;
        box-shadow: unset;
        border-top: 1px solid map-get($colors, bright-gray) !important;
        width: 100%;
    }
    @include breakpoints(medium){
        padding: 10px 20px 0;
    }
    @include breakpoints(small){
        padding: 10px 15px 0;
    }    
    h3{
        font-size: 22px;
        color: map-get($colors , black1);
        letter-spacing: 1px;
        font-weight: 500;
        @include breakpoints(medium){
            font-size: 18px;
        }
        @include breakpoints(small){
            font-size: 15px;
            line-height: normal;
            text-align: center;
            span{
                display: block;
            }
        }
    }
    .nav{
        justify-content: space-between;
        .nav-link{
            
            &.active{
               border:none;
               border-bottom: 4px solid map-get($colors , primary-main);
               h3{
                   color: map-get($colors , primary-main);
               }
            }                
        } 
        &.nav-tabs{
            border-bottom: 4px solid map-get($colors , write-review);
            .nav-item{
                margin-bottom: -4px;
            }
        }
    }
    .nav-tabs{
        .nav-link{
            border: 0;
            padding: 0.5rem 3rem;
            @include breakpoints(small){
                padding: 0.5rem 0;
            }
            &:hover{
                border-top:none;
                border-left: none;
                border-right: none;
            }
            &:focus{
                border-top:none;
                border-left: none;
                border-right: none;
            }
        }
    }
    .tab-content{
        padding: 25px 0;
        position: relative;
    }    
    .profile-ins-list{
        @include wrap-flx;
        justify-content: space-between;
        li{
            width: 27%;
            position: relative;
            padding: 0 0 0 38px;
            @include medium-font;
            color:map-get($colors , black1);
            margin-bottom: 20px;
            &:before{
                @include sprite;
                background-size: 360px;
                width: 26px;
                height: 26px;
                background-position: -334px -126px;
            }
            @include breakpoints(medium){
                width: 45%;
            }
            @include breakpoints(small){
                width: 100%;
            }
        }
    }
    .focus-area-list{
        @include wrap-flx;
        justify-content: space-between;
        li{
            width: 100%;
            position: relative;
            padding-left: 38px;
            @include medium-font;
            color:map-get($colors , black1);
            margin-bottom: 20px;
            &:before{
                // @include profile-icons;
                content: '+';
                position: absolute;
                top: -15px;
                font-size: 40px;
                font-weight: 700;
                line-height: 1.4;
                left: 0;
                color: map-get($colors, grey-dark);
            }
        }
    }
    .location-map-box{
        max-width: 448px;
        @include breakpoints(medium){
            max-width: 100%;
            margin-bottom: 30px;
        }
        img{
            width: 100%;
        }
    }
    .location-add-profile{
        li{
            margin-bottom: 35px;
            @include breakpoints(medium){
                &:last-child{
                    margin-bottom: 0;
                }
            }         
            @include reg-font;
            .location-head{
                @include bold-font;
                margin:20px 0 5px;
                &:first-child{
                    margin-top: 0;
                }
            }
            .loca-hours{
                @include wrap-flx;
                span{
                    &:first-child{
                        width: 120px;
                    }
                }
            }
            .location-link{
                margin-top: 20px;
                @include location-link;
            }
            &:before{
                left: -35px;
            }
            @include breakpoints(medium){
                padding-left: 35px;
                &:before{
                    left: 0;
                }
            }
        }
    }
}

.edit-tabs-btn{
    position: absolute;
    right: 0;
    top: 25px;
    cursor: pointer;
    z-index: 1;
    display: none;
}
.add-tabs-btn{
    position: absolute;
    right: 0;
    top: 25px;
    cursor: pointer;
    z-index: 1;
    display: none;
}
