.policy-terms-wrap {
  margin-bottom: 70px;
  @include breakpoints(small) {
    margin-bottom: 10px;
  }
  .privacy-terms-heading-wrap {
    h1 {
      margin-top: 30px;
      @include breakpoints(small) {
        margin-top: 15px;
      }
    }
    .sub-head-terms {
      @extend %privacy-terms-font;
      margin-bottom: 30px;
      @include breakpoints(small) {
        margin-bottom: 15px;
      }
      span {
        color: map-get($colors, primary-main);
        font-weight: 500;
      }
    }
  }
  .privacy-bodytext {
    span {
      color: #000000 !important;
    }
    @extend %privacy-terms-font;
    p {
      padding: 0;
      margin: 0 0 20px 0;
    }
    h2 {
      font-weight: 700;
      margin-bottom: 17px;
    }
    ul {
      > li {
        position: relative;
        padding-left: 20px;
        margin-bottom: 15px;
        &:before {
          content: "•";
          font-size: 36px;
          left: 0;
          position: absolute;
          top: -16px;
          color: map-get($colors, black1);
        }
        li {
          font-size: 15px;
          position: relative;
          padding-left: 20px;
          margin-top: 5px;
          &:before {
            content: "•";
            font-size: 30px;
            left: 0;
            position: absolute;
            top: -11px;
            color: map-get($colors, filter-border);
          }
          li {
            list-style-type: circle;
            list-style-position: outside;
            padding-left: 0;
            margin-left: 20px;
            font-size: 14px;
            &:before {
              display: none;
            }
            li {
              list-style-type: square;
            }
          }
        }
      }
    }
  }
}
