.searchby-container{
    margin-top: 33px;
    h2{
        margin-bottom: 24px;
    }
}    
.searchBy {
    @include box-shadow-style;
    border-radius: 10px;
    padding: 52px 33px; 
    min-height: 638px;
    @include breakpoints(medium) {
        padding: 35px 15px; 
    }
    @include breakpoints(small) {
        padding: 20px 0px; 
    }
    .provider-steps-wrap{
        .language-wrap-step2{
            .search-insurance{
                width: 30%;
                @include breakpoints(small) {
                    width: 100% !important;
                }
            }
        }
        .next-btn-pro{
            width: 219px !important;
            height: 45px;
            padding: 0;
        }
    }
    .home-top-fields{
        &.searchFilter {
            margin-top: 0;
            padding-bottom: 0;
            margin-bottom: 30px;
            justify-content: flex-start;
            @include breakpoints(medium){
                margin-bottom: 10px;
            }
            @include breakpoints(small){
                width: 100%;
                margin-bottom: 0;
            }
            span {
                &:nth-child(1){
                    width: 440px;
                    margin-right: 20px;
                    @include breakpoints(large){
                        width: 35%;
                    }
                    @include breakpoints(medium){
                        width: 34%;
                    }
                    @include breakpoints(small){
                        width: 100%;
                        margin-right: 0;
                    }
                }
                &:nth-child(2){
                    width: 250px;
                    margin-right: 20px;
                    @include breakpoints(large){
                        width: 30%;
                    }
                    @include breakpoints(small){
                        width: 100%;
                        margin-right: 0;
                        margin-bottom: 10px;
                    }
                }
                &:nth-child(3){
                    width: 306px;
                    @include breakpoints(large){
                        width: 30%;
                    }
                    @include breakpoints(small){
                        width: 100%;
                        margin-right: 0;
                    }
                }
                .input-home {
                    border-radius: 0;
                    box-shadow: unset;
                    padding: 19px 20px;
                    @include breakpoints(small){
                        padding: 15px 10px;
                    }
                }
            }  
            .myform{
                select{
                    padding: 19px 20px 19px;
                    font-weight: 500;
                    letter-spacing: 0.5px;
                    color: #333333;
                    // background-image: url("#{$imagePath}drop-arrow-search.png");
                    // background-size: 17px;
                    font-family: $roboto;
                    font-size: 14px;
                    @include breakpoints(small){
                        padding: 14px 20px 13px;
                    }
                }
            }      
        }
    }
    
    .noMarginCity {
        margin-right: 19px !important;
        cursor: pointer;
        span {
            float: right;
            padding-right: 10px;
        }
        .input-group-text {
            background-color: transparent;
        }
        .input-group-prepend {
            width: 90%;
            padding: 1rem 0;
            span:nth-of-type(2) {
                width: 100%;
            }
        }
    }
}