.cookies-wrap{
    background-color: map-get($colors, cookiesbg);
    padding: 20px 0;
    .cookies-body{
        @extend %cookies-body-font;
        p{
            margin: 0 0 20px;
            span{
                font-weight: 700;
            }
        }
    }
    .cookies-btn{
        text-align: right;
        .next-btn-pro{
            width: 330px;
            margin: 0;
        }
    }
}