.dochowwork {
    
    padding: 27px 10px 57px 10px;
    
   
    
    .box {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        .rect {
            width: 40%;
            max-width: 425px;
            margin: 30px;
            padding: 22px 9px 22px 9px;
            // border-radius: 3px;
            // border: solid 1px map-get($colors, black1);
            // background-color: map-get($colors, write-review); 
            text-align:center;
            .title {
                font-size: 22px;
                font-weight: 500;
                letter-spacing: 0.52px;
                margin: 10px 0 5px;
            }
            .digit {
                font-size: 70px;
                font-weight: bold;
                letter-spacing: 1.67px;
            }
           
            @include breakpoints('medium') {
                width:100%;
            }
        }
    }
    .share-score{
        @include wrap-flx;
        justify-content: flex-end;
        align-items: center;
        span{
            margin-right: 10px;
            &:last-child{
                margin: 0;
            }
        }
    }
}