.profile-plans{
    .cancel-link{
        display: block;
        position: absolute;
        bottom:-33px;
        width: 100%;
        text-align: center;
        font-weight: 500;
        a{
            color:#d93025;
           &:hover{
                background-color: transparent !important;
                color: red;
           }
        }
    }
    .provider-steps-wrap{
        display: none;
    }
    .provider-step4-inner{
        padding-bottom: 40px;
        max-width: 100%;
        @include breakpoints(medium){
            padding-top: 0;
        }
    }
}
.card-detail-outer{
    padding: 0 10px;
    width: 100%;
}
.profile-billing{
    @include box-shadow-style;
    @include border-radius(10px);
    padding: 30px 30px 60px;
    margin-bottom: 50px;
    @include breakpoints(small){
        padding: 20px 5px 30px;
        .add-link{
            font-size: 16px;
        }
    }
    h2{
        font-weight: 500;
        letter-spacing: 1px;
        @include breakpoints(small){
            padding: 0 15px;
        }
    }
    .smalltxt{
        @include bold-font;
        margin: 10px 0 10px;
        color: map-get($colors, grey-dark);
        @include breakpoints(small){
            padding: 0 15px;
        }
    }
    .seaprator-title, .description{
        // display: none;
    }
    .provider-form-inner{
        width: 940px;
        @include breakpoints(medium){
            width: 100%;
        }
    }
}
.card-detail-wrap{    
    max-width: 100%;
    margin-top: 50px;
    @include breakpoints(medium){
        overflow-x: scroll;
    }
    .card-detail-inner{
        max-width: 940px;
        margin: 0 auto;
        @include breakpoints(medium){
            max-width: none;
            width: 940px;
        }
        @include breakpoints(small){
            width: 860px;
        }
        .card-detail-table{
            @include wrap-flx;            
            color: map-get($colors, black1);
            border-bottom: 2px solid map-get($colors, white-smoke);
            padding-bottom: 30px;
            margin-bottom: 30px;
            &:first-child{
                padding-bottom: 20px;
                margin-bottom: 30px;
            }
            .card-table-head, .card-table-body{                
                &:nth-child(1){
                    width: 8%;
                    margin-right: 6%;
                    @include breakpoints(small){
                        width: 7%;
                        margin-right: 4%;
                    }
                }
                &:nth-child(2){
                    width: 26%;
                    @include breakpoints(small){
                        padding-right: 3%;
                        width: 36%
                    }
                }
                &:nth-child(3){
                    width: 25%;
                    @include breakpoints(small){
                        width: 20%;
                    }
                }
                &:nth-child(4){
                    width: 15%;
                }
                &:nth-child(5){
                    width: 15%;
                    margin-left: 5%;
                }
            }
            
            .card-table-head{
                font-size: 20px;
                font-weight: 500;
                @include breakpoints(small){
                    font-size: 16px;
                }
            }
            .card-table-body{
                @include medium-font;
                line-height: 1.4;
                color: map-get($colors, grey-dark);               
                &:nth-child(1){
                    text-align: center;
                }
                span{
                    display: block;
                    color: map-get($colors, black1);
                    margin-top: 10px;
                }
            }
            .cus-radio{
                label{
                    @include bold-font;
                    &:before{
                        height: 24px;
                        width: 24px;
                    }
                }            
                >input{
                    &:checked{
                        +label{
                            &:before{
                                border-color: map-get($colors, primary-main);
                            }
                            &:after{
                                top: 10px;
                                left: 4px;
                                width: 16px;
                                height: 16px;
                            }
                        }
                    }
                }
            }
        }
    }
    .dlt-edit-link{
        color: map-get($colors, primary-main);        
        small{
            margin: 0 7px;
            font-size: 16px;
        }        
    }

}   
.add-link-card-detail{
    max-width: 940px;
    margin: 0 auto;
    @include breakpoints(medium){
        width: 100%;
    }
}
.card-buttons{
    @include wrap-flx;
    justify-content: center;
    margin-top: 20px;   
    width: 100%;
    display: none;
    text-align: center;
    input{
        width: 240px;
        @include breakpoints(medium){
            margin-top: 10px;
        }
        &:first-child{
            margin-right: 20px;
            @include breakpoints(small){
                margin-right: 0;
            }
        }
    }
}
.card-detail-outer{
    .card-buttons{
        display: block;
    }
}
.manage-cards{  
    .cardFormContainer{
        .card-buttons{
            display: block;
            margin-top: 0;
            input{
                width: 180px;
                @include breakpoints(small){
                    width: 140px;
                    &:first-child{
                        margin-right: 10px;
                    }
                }
            }
        }
    }      
    .provider-form-inner{
        width: 600px;
        @include breakpoints(small){
            width: 100%;
            .pay-icon{
                width: 230px;
                position: relative;
                top: 3px;
            }
        }
    }
    .cardForm{
        @include breakpoints(medium){
            margin-top: 20px;
        }
        @include breakpoints(small){
            margin-top: 10px;
        }
    }
    .cardForm{
        .payment-submit{
            display: none;
        }
    }
}
.dlt-profile-security{
    margin: 60px 0 60px;
    .card-buttons{
        display: block;
        input{
            margin-top: 0;
            width: 205px;
            font-size: 16px;
        }
    }
}

