.provider-login-wrap{
    max-width: 470px !important;
    margin: 50px auto 180px;
    &.patient {
        max-width: 580px !important;
        margin: 50px auto 38px;
        padding: 0 44px;
        @include breakpoints(medium){
            padding: 0;
        }
        h1{
            border-bottom: 1px solid map-get($colors, bright-gray);
            padding-bottom: 24px;
            margin-bottom: 15px;
            line-height: normal;
        }
        p{
            padding: 0;
            margin: 0;
            font-size: 14px;
            letter-spacing: 0.75px;
            line-height: 1.5;
        }
        a {
            display: inline-flex;
        }
        .pro-label{
            display: none;
        }
        .next-btn-pro{
            margin: 0;
        }
        .provider-steps-wrap {
            .medical-info {
                @extend %body;
            }
        }
        .google-box{
            width: 100%;
            position: relative;
            background-color: map-get($colors, white1);
            @include box-shadow(0 0 10px 0 rgba(0, 0, 0, 0.16));
            padding: 15px 0;
            @include wrap-flx;
            justify-content: center;
            align-items: center;
            margin: 10px 0;
            cursor: pointer;
            span{
                font-size: 15px;
                letter-spacing: 1.3px;
                color: map-get($colors, rev-chk-label);
                font-weight: 700;
                padding-left: 18px;
            }
        }
        .provider-steps-wrap{
            .medical-info{
                font-size: 13px;
            }
        }
        
    }
    .loggedout-msg{
        @extend %loggedout-msg-font;
        color: map-get($colors, green-shade);
        @include wrap-flx;
        justify-content: space-between;
        margin-bottom: 20px;
        .r-msg{
            width: 88%;
            @include breakpoints(small){
                width: 83%;
            }
        }
        .l-msg{
            width: 12%;
        }
        .right-mark{
            width: 40px;
            height: 40px;
            &:after{
                top: 3px;
                width: 14px;
                height: 27px;
            }
        }
    }
    &.twostep {
        max-width: 570px !important;
        margin: 50px;
        padding-left: 50px;
        padding-right: 50px;
        @include breakpoints(small) {
            padding-left: 0;
            padding-right: 0;
        }
    }
    .suc-msg {
        h3 {
            margin: 0px;
            font-size: 21px;
            line-height: 1.62;
            letter-spacing: 0.88px;
            font-weight: bold;
            
            @include breakpoints(small) {
                line-height: 26px;
            }
            @include breakpoints(small) {
                line-height: 30px;
            }
            &.italic {
                font-style: italic;
                @extend %contactus-error-font;
            }
            &.error-msg-404 {
                margin-bottom: 75px; 
                &:first-child {
                    margin-bottom: 45px; 
                } 
            }
        }
        .cus-check {
            & label {
                &:before {
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    background-color: map-get($colors, success-green);
                    border: unset;
                }
                &:after {
                    top: 16px;
                    left: 30px;
                    width: 12px;
                    height: 26px;
                }
            }
        }
        
    }
    &.service-area-soon {
        max-width: 800px !important;
        margin: 0;

    }
    @include breakpoints(small){
        margin: 30px auto 30px;
    }
    h1{
        text-align: center;
    }
    p{
        font-size: 16px;
        font-weight: 300;
        padding: 0;
        margin: 25px 0;
        color: #606060;  
        line-height: 30px;
        letter-spacing: 0.8px;
        text-align: center;
        a{
            color: map-get($colors, primary);
            font-weight: 700;
        }
        &.small {
            font-size: 14px;
            line-height: 1.5;
            letter-spacing: 0.75px;
            text-align: center;
            color: map-get($colors,grey-dark);
            strong {
                font-weight: 500;
                color: map-get($colors, black_color);
            }
        }
        
    }
    .provider-login-form-wrap{
        span{
            display: block; 
            margin-bottom: 20px; 
            position: relative;
            &:nth-child(2){
                margin-bottom: 0;
            }
        }
        .pro-forgot{
            display: flex;
            flex-wrap:wrap;
            justify-content: space-between;
            a{
                color: #077db4;
                font-size: 13px;
            }
        }
    }
    .submit-btn {
        margin-top: 10px;
        margin-bottom: 25px !important;
    }
    .link-text {
        margin: 0px auto;
        letter-spacing: 0.3px;
        a{
            color: map-get($colors, primary);
        }
    }
    @include breakpoints(small){
        .bday-use{
            strong{
                display: block;
            }
        }
    }
    .forgot-login, .keep-log{
        font-size: 13px;
        color: map-get($colors, grey-dark);
    }
    .forgot-login{
        a{
            color: map-get($colors, primary);
        }
    }
    .service-area-wrapper {
        padding-left: 56px;
        padding-right: 56px;
        padding-top: 20px;
        padding-bottom: 25px;
        @include breakpoints(small) {
            padding-left: 0px;
            padding-right: 0px;
        }
        h4 {
            strong {
                font-weight: 500;
                color: map-get($colors, light-red);
            }
        }
    }
}