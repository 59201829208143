$body: (null: (16px, 1.5), medium: (16px, 1.5), large: (16px, 1.5), xlarge: (16px, 1.5));
$scheduling-page: (null: (14px), medium: (14px), large: (14px), xlarge: (14px));
$aboutus-content: (null: (18px, 1.67, 0.86px), small: (20px, 1.67, 0.86px), medium: (22px, 1.67, 0.86px), xlarge: (24px, 1.67, 0.86px));
$contactus-label: (null: (16px, 1, 0.38px), medium: (16px, 1, 0.38px), large: (16px, 1, 0.38px), xlarge: (16px, 1, 0.38px));
$contactus-placeholder: (null: (14px), medium: (14px), large: (14px), xlarge: (14px));
$suc-anchor: (null: (14px, 20px, 0.48px), small: (16px, 35px, 0.48px), medium: (18px, 39px, 0.48px), large: (18px, 39px, 0.48px), xlarge: (20px, 39px, 0.48px));
$covid-screen-box-1: (null: (14px, 1.3, 0.75px), small: (16px, 1.3, 0.83px), medium: (18px, 1.3, 0.83px), large: (18px, 1.3, 0.83px), xlarge: (20px, 1.3, 0.83px));
$covid-screen-box-2: (null: (14px, 1.5, 0.75px), small: (14px, 1.5, 0.75px), medium: (16px, 1.5, 0.75px), large: (16px, 1.39, 0.75px), xlarge: (18px, 1.39,  0.83x));

$contactus-error: (null: (20px, 40px, 1px), small: (22px, 40px, 0.48px), medium: (24px, 49px, 1px), large: (26px, 49px, 1px), xlarge: (28px, 49px, 1px));
$privacy-terms:(null:(14px, 1.6, 0.6px), medium:(16px, 1.6, 0.6px), large:(16px, 1.6, 0.6px), xlarge:(16px, 1.6, 0.6px));
$notification-heading:(null:(16px, 1.56, 0.75px), medium:(18px, 1.56, 0.75px), large:(18px, 1.56, 0.75px), xlarge:(18px, 1.56, 0.75px));
$notification-description:(null:(14px, 1.53, 0.5px), medium:(15px, 1.53, 0.5px), large:(15px, 1.53, 0.5px), xlarge:(15px, 1.53, 0.5px));
$notification-title:(null:(20px, 1.53, 0.62px), medium:(22px, 1.53, 0.62px), large:(24px, 1.53, 0.62px), xlarge:(24px, 1.2, 1px));

$hg-heading: (null: (18px, 1.5, 0.5px), medium: (18px, 1.5, 0.5px), large: (18px, 1.5, 0.5px), xlarge: (20px, 1.5, 0.5px));
$hg-label:(null: (14px, 1.5, 0.5px), medium: (16px, 1.5, 0.5px), large: (16px, 1.5, 0.5px), xlarge: (16px, 1.5, 0.5px));
$hg-fields:(null: (14px, 1.5, 0.5px), medium: (14px, 1.5, 0.5px), large: (14px, 1.5, 0.5px), xlarge: (14px, 1.5, 0.5px));
$hg-result:(null: (18px), medium: (22px), large: (22px), xlarge: (22px));

$care-team-subhead:(null: (18px, 1.2, 0.83px), medium: (20px, 1.2, 0.83px), large: (20px, 1.2, 0.83px), xlarge: (20px, 1.2, 0.83px));
$save-unsave:(null: (16px), medium: (16px), large: (18px), xlarge: (18px));

$all-filter:(null: (14px), medium: (14px), large: (14px), xlarge: (16px));
$loggedout-msg:(null: (14px, 1.4), medium: (16px, 1.4), large: (18px, 1.4), xlarge: (18px, 1.4));
$profile-appointment:(null: (16px, 1.5, 0.8px), medium: (16px, 1.5, 0.8px), large: (20px, 1.5, 0.8px), xlarge: (20px, 1.5, 0.8px));
$myaccount-menu:(null: (14px), medium: (14px), large: (16px), xlarge: (18px));

$demo-medical-head:(null: (16px), medium: (18px), large: (18px), xlarge: (18px));
$cookies-body:(null: (12px, 1.5, 1px), medium: (13px, 1.5, 1px), large: (15px, 1.5, 1px), xlarge: (15px, 1.5, 1px));

$covid-questions: (null: (16px, 1.5, 1px), medium: (22px, 1.5, 1px), large: (24px, 1.5, 1px), xlarge: (24px, 1.5, 1px));
$covid-label:(null: (14px), medium: (16px), large: (16px), xlarge: (16px));
$covid-back:(null: (16px, 1.5, 1.13px), medium: (18px, 1.5, 1.13px), large: (18px, 1.5, 1.13px), xlarge: (18px, 1.5, 1.13px));

$logo-text:(null: (25px, 20px), medium: (34px, 34px), large: (34px, 34px), xlarge: (34px, 34px));
$th-text:(null: (16px), medium: (14px), large: (16px), xlarge: (16px));
$td-text:(null: (16px), medium: (14px), large: (16px), xlarge: (16px));
$content-manage-text:(null: (14px), medium: (16px), large: (18px), xlarge: (18px));
$rating-detail-head:(null: (16px), medium: (16px), large: (18px), xlarge: (18px));
$rating-detail-text:(null: (14px), medium: (14px), large: (16px), xlarge: (16px));
$table-heading:(null: (18px, 1.3, 1.1px), medium: (20px, 1.4, 1.1px), large: (20px, 1.4, 1.1px), xlarge: (20px, 1.4, 1.1px));


body,
%body,
.body {
  @include font-size($body);
  font-family: $roboto;
  font-weight: normal;
}


%scheduling-page-font,
.scheduling-page-font {
  @include font-size($scheduling-page);
  font-family: $roboto;
  font-weight: normal;
}

%aboutus-content-font,
.aboutus-content-font {
  @include font-size($aboutus-content);
  font-family: $roboto;
  font-weight: normal;
}

%contactus-label-font,
.contactus-label-font {
  @include font-size($contactus-label);
  font-family: $roboto;
  font-weight: 500;
}
%contactus-error-font,
.contactus-error-font {
  @include font-size($contactus-error);
  font-family: $roboto;
  font-weight: normal;
}

%contactus-placeholder-font,
.contactus-placeholder-font {
  @include font-size($contactus-placeholder);
  font-family: $lato;
  font-weight: normal;
}

%suc-anchor-font,
.suc-anchor-font {
  @include font-size($suc-anchor);
  font-family: $roboto;
  font-weight: 500;
}

%privacy-terms-font,
.privacy-terms-font{
  @include font-size($privacy-terms);
  font-family: $roboto;
  font-weight: 400;
}

%covid-screen-box-font-1,
.covid-screen-box-font-1 {
  @include font-size($covid-screen-box-1);
  font-family: $roboto;
  font-weight: bold;
}

%covid-screen-box-font-2,
.covid-screen-box-font-2 {
  @include font-size($covid-screen-box-2);
  font-family: $roboto;
  font-weight: normal;
}


%notification-heading-font,
.notification-heading-font {
  @include font-size($notification-heading);
  font-family: $roboto;
  font-weight: normal;
}
%notification-description-font,
.notification-description-font {
  @include font-size($notification-description);
  font-family: $roboto;
  font-weight: normal;
}

%notification-title-font,
.notification-title-font {
  @include font-size($notification-title);
  font-family: $roboto;
  font-weight: 500;
}
%calc-heading-font,
.calc-heading-font{
  @include font-size($hg-heading);
  font-family: $roboto;
  font-weight: 500;
}
%hg-label-font,
.hg-label-font {
  @include font-size($hg-label);
  font-family: $roboto;
  font-weight: 500;
}

%hg-fields-font,
.hg-fields-font {
  @include font-size($hg-fields);
  font-family: $roboto;
  font-weight: normal;
}
%hg-result-font,
.hg-result-font{
  @include font-size($hg-result);
  font-family: $roboto;
  font-weight: 700;
}
%care-team-subhead-font,
.care-team-subhead-font{
  @include font-size($care-team-subhead);
  font-family: $roboto;
  font-weight:500;
}
%save-doc-font,
.save-doc-font{
  @include font-size($save-unsave);
  font-family: $roboto;
  font-weight: 500;
}
%all-filter-font,
.all-filter-font{
  @include font-size($all-filter);
  font-family: $roboto;
  font-weight: 400;
}
%loggedout-msg-font,
.loggedout-msg-font{
  @include font-size($loggedout-msg);
  font-family: $roboto;
  font-weight: 500;
}
%profile-appointment,
.profile-appointment{
  @include font-size($profile-appointment);
  font-family: $roboto;
  font-weight: 400;
}
%myaccount-menu-font,
.myaccount-menu-font{
  @include font-size($myaccount-menu);
  font-family: $roboto;
  font-weight: 400;
}
%demo-medical-head-font,
.demo-medical-head-font{
  @include font-size($demo-medical-head);
  font-family: $roboto;
  font-weight: 500;
}
%cookies-body-font,
.cookies-body-font{
  @include font-size($cookies-body);
  font-family: $roboto;
  font-weight: 400;
}
%covid-questions-font,
.covid-questions-font{
  @include font-size($covid-questions);
  font-family: $roboto;
  font-weight: 400;
}
%covid-label-font,
.covid-label-font{
  @include font-size($covid-label);
  font-family: $roboto;
  font-weight: 400;
}
%covid-back-font,
.covid-back-font{
  @include font-size($covid-back);
  font-family: $roboto;
  font-weight: 700;
  color: map-get($colors, primary-main);
}
%logo-text-font,
.logo-text-font{
  @include font-size($logo-text);
  font-family: $lato;
  font-weight: 700;
  color: map-get($colors, primary-main);
}
%th-text-font,
.th-text-font{
  @include font-size($th-text);
  font-family: $roboto;
  font-weight: 500;
  color: map-get($colors, thcolor);
}
%td-text-font,
.td-text-font{
  @include font-size($td-text);
  font-family: $roboto;
  font-weight: 400;
  color: map-get($colors, grey-dark);
}
%content-manage-text-font,
.content-manage-text-font{
  @include font-size($content-manage-text);
  font-family: $roboto;
  font-weight: 400;
  color: map-get($colors, black1);
}
%rating-detail-head-font,
.content-manage-text-font{
  @include font-size($rating-detail-head);
  font-family: $roboto;
  font-weight: 500;
  color: map-get($colors, black1);
}
%rating-detail-text-font,
.content-manage-text-font{
  @include font-size($rating-detail-text);
  font-family: $roboto;
  font-weight: 400;
  color: map-get($colors, black1);
}

%table-heading-font,
.table-heading-font{
  @include font-size($table-heading);
  font-family: $roboto;
  font-weight: 700;
}
