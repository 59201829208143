// In `_config.scss`
// $small: 0px;
// $medium: 768px;
// $large: 1024;
// $xlarge: 1200;
// $breakpoints: (
//   'small'  : $small,
//   'medium' : $medium,
//   'large'  : $large,
//   'xlarge' : $xlarge,
// ) !default;

// In `_mixins.scss`
@mixin breakpoints($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (max-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }

  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
  }
}

// In `_random-component.scss`
