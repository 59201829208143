.pagination-wrap{
    margin: 20px 0 60px;
    @include bold-font;
    @include breakpoints(medium){
        margin-bottom: 30px;
    }
    @include breakpoints(small){
        font-size: 12px;
    }
    font-weight: 700;
    a{
        border: 0;
    }
    .page-item{
        &.active{
            .page-link{
                background-color:map-get($colors, grey-dark);
                color: map-get($colors, white1);
                @include border-radius(3px);
            }
        }
        &.dot-pagination{
            padding: 0.5rem 0.8rem;
        }
        &.prev-space{
            margin-right: 80px;
            @include breakpoints(small){
                margin-right: 0px;
            }
        }
        &.next-space{
            margin-left: 80px;
            @include breakpoints(small){
                margin-left: 0px;
            }
        }
    }
    .page-link{
        color:map-get($colors, grey-dark);
        margin: 0 0.5rem;
        padding: 0.5rem 0.8rem;
        @include breakpoints(small){
            margin: 0 0;
            padding: 0.4rem 0.6rem;
        }
        &:hover{
            @include border-radius(3px);
        }
        &:focus{
            @include box-shadow(none);
        }
    }
}