.covid-screens-wrap{
    h1{
        margin: 30px 0 25px;
        letter-spacing: 1.1px;
        @include breakpoints(small){
            margin: 20px 0 15px;
        }
    }
    .covid-screens {
        @include box-shadow-style;
        @include border-radius(8px);
        border: 0;
        padding: 45px 70px 150px; 
        margin-bottom: 60px;    
        @include breakpoints(medium){
            padding: 20px 20px 80px;
        }   
        @include breakpoints(small){
            margin-bottom: 40px;    
        }  
        .space-covid-screen1{
            margin-bottom: 50px;
            @include breakpoints(small){
                margin-bottom: 30px;    
            }
        }        
        .box {
            @include box-shadow(0 4px 6px 0 rgba(0, 0, 0, 0.19));
            background-color: map-get($colors, banana-mania);
            border: 0;
            display: flex;
            padding: 21px 47px;
            align-items: center;
            margin-bottom: 50px;
            @include breakpoints(medium){
                padding: 20px 20px;
                margin-bottom: 30px;
            } 
            img {
                height: 60px;
                width: 60px;
                margin-right: 35px;
                @include breakpoints(small){
                    margin: 0 auto;
                } 

            }
            div {
                @extend %covid-screen-box-font-2;
                &:first-child {
                    @extend %covid-screen-box-font-1;
                    margin-bottom: 10px;
                }
            }
            @include breakpoints(small) {
                flex-direction: column;
                padding: 21px 21px;
                align-items: flex-start;
                margin-bottom: 30px;
                text-align: center;
                div {
                    &:first-child {
                        margin-top: 15px;
                        margin-bottom: 15px;
                    }
                }
            }
        }
        .list {
            padding-left: 0px;
            padding-right: 0px;
            @extend %covid-screen-box-font-1;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: 0.6px;
            font-weight: 500;
            ul {
                @extend %covid-screen-box-font-2;
                line-height: 2.17;
                letter-spacing: 0.43px;
                font-weight: 500;
                margin-top: 25px;
                margin-bottom: 33px;
                @include breakpoints(small){
                    margin: 20px 0;
                }
                li {
                    list-style: disc;
                    list-style-position: inside;
                }
            }
            .note {
                @extend %covid-screen-box-font-2;
                line-height: 1.61;
                letter-spacing: 0.43px;   
                font-weight: normal;
                color : map-get($colors, light-red );
                span {
                    font-weight: bold;
                }
            }
            @include breakpoints(small) {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
        .button-section {
            display: flex;
            align-items: center;
            margin-top: 50px;
            flex-wrap: wrap;
            @include breakpoints(small) {
                margin-top : 20px;
            }
            .next-btn-pro {
                margin-top: 0;
                margin-right: 50px;
                width: 215px;
                font-weight: 400;
                @include breakpoints(small) {
                    margin-right: 0px;
                    margin-bottom : 15px;
                }
            }
            .link {
                
                color: map-get($colors, primary-main);
                font-weight: bold;
                text-decoration: underline;

            }
            @include breakpoints( small ) {
                justify-content: center;
            }
            
        }
        @include breakpoints(small) {
            padding: 0 0 40px 0;
        }
    }
// ***********Self assessment****************
    .progress-outer{
        background-color: map-get($colors, write-review);
        height: 4px;
        width: 100%;
        .progress-inner{
            background-color: map-get($colors, primary-main);
            height: 100%;;
        }
    }
    .covid-self-outerbox{
        @include box-shadow-style;
        @include border-radius(8px);
        margin: 40px auto 100px;
        padding: 55px 55px 30px;
        width: 100%;
        max-width: 800px;
        border: 0;
        @include breakpoints(medium){
            margin: 30px auto 50px;
            padding: 30px 30px;
            max-width: 100%;
        }
        @include breakpoints(small){
            margin: 30px auto 40px;
            padding: 20px;
        }
        .covid-question{
            @extend %covid-questions-font;
            margin-bottom: 35px;
            position: relative;
            padding-left: 35px;
            @include breakpoints(small){
                margin-bottom: 15px;
                padding-left: 25px;
            }
            span{
                position: absolute;
                left: 0;
                top: 0;
            }
        }
        .covid-question-options{
            padding: 0;
            .cus-radio{
                margin-bottom: 15px;
                &:last-child{
                    margin-bottom: 0;
                }
                >label{
                    display: block;
                    border: 1px solid map-get($colors , write-review);
                    @include border-radius(4px);
                    @extend %covid-label-font;
                    padding: 18px 20px 22px 60px;    
                    position: relative;       
                    @include breakpoints(small){
                        padding: 13px 10px 12px 40px;
                    }         
                    &:before{
                        width: 22px;
                        height: 22px;
                        margin-right: 0;
                        top: 19px;
                        left: 21px;
                        border-color: map-get($colors , primary-main);
                        position: absolute;
                        @include breakpoints(small){
                            top: 12px;
                            left: 10px;
                        }                        
                    }
                }
                > input:checked + label:after{
                    top: 23px;
                    left: 25px;
                    width: 14px;
                    height: 14px;
                    @include breakpoints(small){
                        top: 16px;
                        left: 14px;
                    }
                }
            }
            .cus-check-rev{
                margin-bottom: 15px;
                &:last-child{
                    margin-bottom: 0;
                }
                >label{
                    display: block;
                    border: 1px solid map-get($colors , write-review);
                    @include border-radius(4px);
                    @extend %covid-label-font;
                    padding: 21px 20px 19px 60px;    
                    position: relative;     
                    @extend %covid-label-font;     
                    @include breakpoints(small){
                        padding: 18px 10px 18px 40px;
                    }      
                    &:before{
                        width: 22px;
                        height: 22px;
                        margin-right: 0;
                        top: 21px;
                        left: 21px;
                        border-color: map-get($colors , primary-main);
                        position: absolute;
                        @include breakpoints(small){
                            top: 18px;
                            left: 10px;
                        } 
                    }
                }
                > input:checked + label:after{
                    top: 23px;
                    left: 29px;
                    @include breakpoints(small){
                        top: 20px;
                        left: 18px;
                    }
                }
            }
        }
        .covid-buttons{
            @include wrap-flx;
            justify-content: space-between;
            align-items: center;
            margin-top: 30px;
            .next-btn-pro{
                margin: 0;
                font-weight: 400;
                width: 120px;
            }
            .covid-bkbtn{
                @extend %covid-back-font;
                position: relative;
                padding-left: 21px;
                cursor: pointer;
                &:before{
                    @include sprite;                    
                    background-position: -198px -366px;
                    width: 15px;
                    height: 20px;                    
                }
            }
        }
    }
    .covid-screen8-outer{
        max-width: 1060px;
        margin: 35px auto 100px;
        @include breakpoints(medium){
            margin: 20px auto 40px;
        }
    }
    .covid-redbox{
        background-color: map-get($colors, covid-red);
    }
    .covid-yellowbox{
        background-color: map-get($colors, covid-yellow);
    }
    .covid-greenbox{
        background-color: map-get($colors, covid-green);
    }
    .covid-redbox, .covid-yellowbox, .covid-greenbox{
        @include wrap-flx;
        @include box-shadow(0 4px 6px 0 rgba(0, 0, 0, 0.19));
        justify-content: space-between;
        padding: 22px 90px 30px 50px;
        margin-bottom: 30px;
        @include breakpoints(medium){
            padding: 22px 40px;
        }
        @include breakpoints(small){
            padding: 20px;
        }
        .left-icon{
            width: 10%;
            @include breakpoints(medium){
                width: 12%;
            }
            @include breakpoints(small){
                width: 60px;
                margin: 0 auto 15px;
            }
            img{
                width: 60px;
                height: 60px;
                opacity: 0.8;
            }
        }
        .right-text{
            width: 90%;
            @include breakpoints(medium){
                width: 87%;
            }
            @include breakpoints(small){
                width: 100%;
                text-align: center;
            }
            span{
                display: block;
                @extend %covid-screen-box-font-2;
                &:first-child{
                    @extend %covid-screen-box-font-1;
                    margin-bottom: 10px;
                }
            }
        }
    }
}