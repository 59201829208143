.provider-steps-wrap {
  .myform {
    @include select-style;
  }
  .provider-form-inner {
    max-width: 700px;
  }
  h1 {
    span {
      font-weight: 400;
      @include breakpoints(medium) {
        display: block;
      }
    }
  }
  .stepper-wrap {
    margin: 35px auto 40px;
    max-width: 990px;
    @include breakpoints(medium) {
      margin: 20px auto;
    }
    .steps {
      list-style: none;
      margin: 0;
      padding: 0;
      display: table;
      table-layout: fixed;
      width: 100%;
      .step {
        position: relative;
        display: table-cell;
        text-align: center;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.4);
        @include breakpoints(small) {
          font-size: 12px;
          span {
            display: block;
          }
        }
        &:before {
          content: attr(data-step);
          display: block;
          margin: 0 auto;
          background-color: map-get($colors, white1);
          border: 2px solid map-get($colors, border-grey);
          color: map-get($colors, grey-light);
          width: 52px;
          height: 52px;
          text-align: center;
          margin-bottom: 22px;
          line-height: 50px;
          border-radius: 100%;
          position: relative;
          z-index: 1;
          font-weight: 600;
          font-size: 20px;
          font-family: $montserrat;
          @include breakpoints(small) {
            width: 45px;
            height: 45px;
            line-height: 45px;
            font-size: 16px;
            margin-bottom: 10px;
          }
        }
        &:after {
          content: "";
          position: absolute;
          display: block;
          background-color: map-get($colors, grey-lighter);
          width: 100%;
          height: 2px;
          top: 27px;
          left: 50%;
        }
        &:last-child:after {
          display: none;
        }
        &.is-complete {
          color: rgba(0, 0, 0, 0.4) !important;
          font-weight: 500;
          &:before {
            content: attr(data-step);
            color: map-get($colors, white1);
            background-color: map-get($colors, primary-main);
            border: 2px solid map-get($colors, primary-main);
          }
          &:after {
            background-color: map-get($colors, primary-main);
          }
        }
        &.is-active {
          font-size: 14px;
          color: map-get($colors, primary-main);
          font-weight: 500;
          @include breakpoints(small) {
            font-size: 12px;
          }
          &:before {
            color: map-get($colors, white1);
            border: 2px solid map-get($colors, primary-main);
            background-color: map-get($colors, primary-main);
            margin-bottom: 22px;
            @include breakpoints(small) {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
  .eye-icon {
    position: absolute;
    right: 30px;
    top: 54px;
    @include breakpoints(small) {
      top: 48px;
    }
  }

  .radio-gender {
    @include radio-check-out;
  }
  .medical-info {
    position: relative;
    text-align: center;
    font-size: 20px;
    color: #000;
    font-weight: 500;
    margin: 0 auto;
    @include breakpoints(medium) {
      font-size: 18px;
    }
    span {
      background-color: map-get($colors, white1);
      position: relative;
      z-index: 1;
      padding: 0 7px;
      @include breakpoints(medium) {
        padding: 5px 7px;
      }
    }
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 49%;
      height: 1px;
      width: 100%;
      background-color: map-get($colors, border-grey);
    }
  }
  .input-bottom-link {
    font-size: 13px;
    text-align: right;
    width: 100%;
    margin: 3px 0 0;
    display: block;
  }
  .next-btn-pro {
    max-width: 80%;
    margin: 0 auto;
  }
  .check-patient {
    margin-top: 10px;
    @include radio-check-out;
    .cus-check-rev {
      &:first-child {
        margin-right: 40px;
      }
      > input {
        &:checked {
          + label {
            &:after {
              top: 4px;
            }
          }
        }
      }
    }
    & label {
      color: map-get($colors, rev-chk-label);
    }
  }
  .form-add-link {
    font-size: 16px;
    font-weight: 500;
    text-align: right;
    // margin-top: 15px;
    @include breakpoints(small) {
      font-size: 13px;
    }
    a {
      color: map-get($colors, primary-main);
    }
  }
  .sub-head-pro-step2 {
    font-size: 20px;
    font-weight: 500;
    color: map-get($colors, black);
    position: relative;
    padding-left: 45px;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      letter-spacing: 0.5px;
      background-repeat: no-repeat;
      background-size: cover;
    }
    &.degree-icon {
      &:before {
        @include sprite;
        background-size: 430px;
        width: 35px;
        height: 28px;
        background-position: -96px -104px;
      }
    }
    &.training-icon {
      &:before {
        @include sprite;
        background-size: 430px;
        width: 36px;
        height: 34px;
        background-position: -397px -196px;
      }
    }
    &.language-icon {
      &:before {
        @include sprite;
        background-size: 550px;
        width: 30px;
        height: 33px;
        background-position: -67px -251px;
      }
    }
    &.head-title {
      @extend %body;
      font-weight: 500;
      line-height: 1.75;
      padding-left: 0px;
    }
  }
  .language-wrap-step2 {
    @include wrap-flx;
    color: map-get($colors, rev-chk-label);
    font-size: 14px;
    justify-content: space-between;
    .cus-check-rev {
      width: 25%;
      margin-bottom: 20px;
      > input {
        &:checked {
          + label {
            &:after {
              top: 3px;
            }
          }
        }
      }
      &.search-page {
        width: 16%;
      }
      @include breakpoints(small) {
        width: 45% !important;
      }
    }
  }
  .language-wrap-step3 {
    .cus-check-rev {
      @include breakpoints(small) {
        width: 100% !important;
      }
    }
  }
  .language-wrap-step3 {
    .cus-check-rev {
      width: 30%;
      &:not(.search-insurance):first-child {
        width: 100%;
        margin-bottom: 30px;
      }
      > label {
        padding-left: 34px;
        &:before {
          position: absolute;
          left: 0;
        }
      }
    }
  }
  div {
    &.search-city {
      // padding-left: 49px;
      ul {
        &.search-by-city {
          font-size: 14px;
          font-weight: normal;
          line-height: 2.5;
          color: map-get($colors, primary-main);
          margin-right: 19px;
          border-right: solid 1px map-get($colors, light-gray);
        }
      }
      &.last {
        ul {
          &.search-by-city {
            margin-right: 0;
            border-right: 0;
          }
        }
      }
      @include breakpoints(medium) {
        margin-bottom: 15px;
        &:nth-child(even) {
          ul {
            &.search-by-city {
              margin-right: 0;
              border-right: 0;
            }
          }
        }
      }
      @include breakpoints(small) {
        ul {
          &.search-by-city {
            margin-right: 0;
            border-right: 0;
          }
        }
      }
    }
  }

  .next-prev-btn-pro {
    @include wrap-flx;
    justify-content: center;
    & .next-btn-pro:not(.full-width) {
      width: 213px;
      margin: 0;
      @include breakpoints(small) {
        width: 140px;
      }
    }
    .prev-btn-pro {
      background-color: map-get($colors, white1);
      border: 1px solid map-get($colors, primary-main);
      color: map-get($colors, primary-main);
      margin-right: 20px;
      text-decoration: none;
      padding: 11px 0;
      letter-spacing: 1px;
      font-size: 18px;
      width: 213px;
      border-radius: 5px;
      font-weight: 500;
      @include breakpoints(small) {
        width: 140px;
      }
      &:hover {
        background-color: map-get($colors, grey-lighter);
      }
    }
  }
  .input-blue {
    color: map-get($colors, primary-main);
  }
  .practice-hour-box {
    @include wrap-flx;
    width: 100%;
    align-items: center;
    margin-bottom: 10px;
    height: 30px;
    @include breakpoints(small) {
      margin-bottom: 20px;
      height: 55px;
    }
    .cus-check-rev {
      label {
        font-weight: 500;
        font-size: 14px;
      }
    }
    .myform {
      select {
        padding: 4px 20px;
        font-family: $lato;
        font-size: 14px;
        font-weight: 400;
        color: map-get($colors, black1);
      }
    }
    span {
      font-weight: 500;
      font-size: 14px;
      &:nth-child(1) {
        width: 164px;
        @include breakpoints(small) {
          width: 100%;
          margin-bottom: 10px;
        }
        &.not-working {
          width: 152px;
          margin: 0;
        }
      }
      &:nth-child(2),
      &:nth-child(4) {
        width: 152px;
        @include breakpoints(small) {
          width: 115px;
        }
      }
      &:nth-child(3) {
        width: 45px;
        text-align: center;
      }
    }
    &.custom-padding {
      height: auto;
      @include breakpoints(small) {
        .myform {
          width: 100%;
        }
      }
    }
  }
  .appoint-req-box {
    @include wrap-flx;
    width: 100%;
    justify-content: space-between;
    font-weight: 500;
    letter-spacing: 0.4px;
    margin-bottom: 40px;
    @include breakpoints(small) {
      font-size: 14px;
      margin-bottom: 20px;
    }
    .info-icon {
      width: 22px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    span {
      @include breakpoints(small) {
        &:first-child {
          width: 65%;
          margin-bottom: 10px;
        }
        &:last-child {
          width: 14%;
        }
      }
    }
  }
  .add-custom-ins {
    @include wrap-flx;
    width: 100%;
    div {
      &:first-child {
        width: 320px;
        margin-right: 13px;
        @include breakpoints(small) {
          width: 190px;
        }
      }
      &:last-child {
        width: 95px;
        .next-btn-pro {
          padding: 13px 0;
          border-radius: 0;
          font-size: 16px;
          font-weight: 400;
        }
      }
    }
  }
  .delete-icon {
    position: absolute;
    right: 16px;
    top: 4px;
    cursor: pointer;
    z-index: 1;
  }
}

.claim-profile-modal {
  #doctor-img {
    margin-right: 10px;
    border: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    @include breakpoints(medium) {
      margin: 0 auto 10px;
    }
  }
  .doctor-desc {
    @include breakpoints(medium) {
      margin: 0 auto;
      text-align: center;
    }
  }
  .doctor-claim {
    margin: 0 auto;
  }
  .result-address-wrap {
    margin-top: 12px;
    .map-address {
      width: 160px;
    }
  }
  h2 {
    font-size: 22px;
    line-height: 35px;
    letter-spacing: 1.13px;
    font-weight: bold;
  }
  .sub-heading {
    margin: 5px 0px;
    text-align: left;
    color: #000000;
    font-size: 16px;
    font-family: "Roboto";
    line-height: 34px;
    letter-spacing: 0.88px;
    font-weight: normal;
  }
  .doctor-description-wrap {
    border: 1px solid #aaaaaa;
    padding: 25px 30px;
  }
  .service-area-wrapper {
    width: 900px;
    @include breakpoints(medium) {
      margin: 12px;
    }
  }
  .next-btn-pro {
    font-size: 15px;
    padding: 10px;
  }
  .seprator {
    border: solid 1px #f0f0f0;
    margin: 20px 0px;
  }
  .dont-see {
    margin-bottom: 30px;
    letter-spacing: 0.88px;
    @include breakpoints(medium) {
      text-align: center;
    }
  }
}
