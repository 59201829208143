.notifications {
  @include box-shadow-style;
  padding: 36px 40px !important;

  @include breakpoints(medium) {
    padding: 36px 15px;
  }
  h3 {
    @extend %notification-title-font;
  }
  .notifications_wrapper {
    max-height: 650px;
    overflow-y: auto;
    @extend %scrollbarDesign;
    padding-right: 20px;
    .section {
      border-bottom: 1px solid map-get($colors, silver-main);
      @extend %notification-description-font;
      color: map-get($colors, grey-dark);
      &.unread {
        background-color: map-get($colors, alice-blue-bg);
      }
      &:first-child {
        border-top: 1px solid map-get($colors, silver-main);
      }
      h4 {
        @extend %notification-heading-font;
        color: map-get($colors, black_color);
      }
    }
  }
}
