.loader-modal {
	margin: auto;
	top: 50%;
	transform: translateY(-50%) !important;
	height: auto;
	margin-top: 0px !important;
	
	.modal-content {
		background-color: unset;
		border: unset;
		align-items: center;
		
	}
	
} 

