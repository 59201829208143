.accordion {
	margin: 50px auto;
	h2 {
		&.accortion-heading {
			font-size: 28px;
			font-weight: bold;
			letter-spacing: 1.17px;
			margin-bottom: 20px;
			@include breakpoints(small) {
				font-size: 18px;
			}
		}
	}
	&-group {
		.panel-title {
			position: relative;
			padding: 15px 40px 15px 20px;
			border-radius: 2px;
			border: solid 1px map-get($colors, primary-main);
			a {
				font-size: 20px;
				font-weight: bold;
				line-height: 36px;
				letter-spacing: 0.83px;
			}
			&:after {
				content: "+";
				position: absolute;
				right: 20px;
				top: 50%;
				transform: translateY(-50%);
				font-size: 32px;
				color: map-get($colors, primary-main);
			}
			&.active {
				&:after {
					content: "-";
					transform: translate(-10% , -50%);
					font-weight: 300;
					font-size: 60px;
					color: map-get($colors, primary-main);
				}
			}
			&.accord {
				border: 0px;
				&:after {
					content: "";
					font-size: 20px;
					top: 44%;
					right: 0px;
					color: map-get($colors, white1);
					@include breakpoints(medium) {
						content: "+";
					}
				}
				&.active {
					&:after {
						content: "";
						right: 0px;
						font-size: 30px;
						color: map-get($colors, white1);
						@include breakpoints(medium) {
							content: "-";
						}
					}
				}
			}
		}
		.panel-content {
			padding: 20px 20px 16px 20px;
			border-radius: 2px;
			border: solid 1px map-get($colors, primary-main);
			font-size: 18px;
			line-height: 25px;
			letter-spacing: 0.75px;
			color: map-get($colors, night-rider);
			border-top: 0;
		}
		margin-bottom: 10px;
	}

}