.aboutus-content {
    @include box-shadow-style;
    padding: 35px 48px; 
    min-height: 467px;
    margin: 15px;
    @extend %aboutus-content-font;
}

.reset-pass-screen-wrap{
    text-align: center;
    h1{
        margin: 30px 0 27px;
        text-align: left;
        @include breakpoints(small){
            margin: 20px 0 10px;
        }
    }
    .reset-pass-screen-text{
        margin: 0 0 70px;
        line-height: 1.5;
        min-height: 520px;
        @include breakpoints(small){
            margin: 0 0 30px;
            padding: 20px;
            min-height: 400px;
        }
        .reset-pass-screen-text-inner{
            max-width: 735px;
            margin: 100px auto 60px;
            text-align: center;
            @include breakpoints(small){
                margin: 30px auto 20px;
            }
        }
    }
    .next-btn-pro{
        width: 215px;
        height: 52px;
        margin: 0 auto;
    }
}