.recent-review {
    @include box-shadow-style;
    border-radius: 10px;
    padding: 27px 30px 57px 37px;
    @include breakpoints(small){
        padding: 10px 0 20px;
    }
    .heading-p {
        font-size: 24px;
        font-weight: 500;
        letter-spacing: 0.57px;
    }
    .heading-s {
        text-align: right;
        font-size: 14px;
        letter-spacing: 0.33px;
        color: map-get($colors, grey-dark);
        .pn-txt {
            display: flex;
            justify-content: flex-end;
            color: map-get($colors, black1);
            margin-top: 20px;
            & span {
                    display: flex;
                    align-self: center;
                    justify-content: space-between;
                    width: 110px;
                    div {
                        // display: inline-block;
                        height: 20px;
                        width:20px;
                        &.positive {
                            background-color: map-get($colors,light-green);
                        }
                        &.negative {
                            background-color: map-get($colors,light-red);
                        }
                    }
                    img {
                        height: 20px;
                        width: 20px;
                    }
                    &:first-child {
                        margin-right: 30px;
                    }
            }
            @include breakpoints('small') {
                justify-content: flex-start;
            }
        }
        
        @include breakpoints('small') {
            text-align: left;
            margin: 10px 0;
        }

    }
    
    .slider-txt-container {
        border-right: 1px solid map-get($colors, ford-gray);
        text-align: center;
        margin-top: 54px;
        .title {
            font-size: 16px;
            font-weight: 500;
            line-height: 2.13;
            letter-spacing: 0.67px;
        }
        .digit {
            font-size: 20px;
            font-weight: 500;
            line-height: 2.13;
            letter-spacing: 0.67px;
        }
        @include breakpoints('small') {
            border-right: 0;
            border-bottom: 1px solid map-get($colors, ford-gray );
            margin-bottom: 15px;
            margin-top: 20px;
        }
    }
    
    .slider-container {
        display: flex;
        width: 100%;
        height: 63px;
        font-size: 24px;
        font-weight: 500;
        line-height: 1.42;
        letter-spacing: 1px;
        margin-top: 54px;
        .slider-positive {
            height: 100%;
            border-radius: 3px;
            background-color: map-get($colors,light-green);
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
            width: 50%;
            color: map-get($colors, white1);
            padding-top: 14px;
            text-align: center;
        }
        .slider-negative {
            height: 100%;
            border-radius: 3px;
             background-color: map-get($colors,light-red);
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            width: 50%;
            color: map-get($colors, white1);
            padding-top: 14px;
            text-align: center;
        }
        @include breakpoints( small ) {
            margin-top: 20px;
        }
    }
}